package fr.labodoc.webapp.pages.login.sections

import fr.labodoc.Cookie
import fr.labodoc.Cookies
import fr.labodoc.api.payloads.serializers.EmailAddressAsString
import fr.labodoc.api.payloads.serializers.PasswordAsString
import fr.labodoc.app.data.healthprofessional.model.AdminUserModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocCheckbox
import fr.labodoc.webapp.components.labodocText
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.login.LoginPage
import io.kvision.form.FormPanel
import io.kvision.form.text.Text
import io.kvision.html.Autocomplete
import io.kvision.html.InputType
import io.kvision.html.button
import io.kvision.i18n.I18n
import io.kvision.state.MutableState
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class LoginForm(redirectTo: String?, loginPageState: MutableState<LoginPage.State>) :
  FormPanel<LoginForm.Credentials>(className = "login", serializer = Credentials.serializer()), KoinComponent {
    private val userRepository: UsersRepository by inject()

  @Serializable
  data class Credentials(
    val email: EmailAddressAsString,
    val password: PasswordAsString,
    val rememberMe: Boolean
  ) {
    companion object {
      fun validateEmail(email: Text): String? = EmailAddress(email.value ?: "").fold(
        { I18n.tr("Errors.EmailAddress.Invalid") },
        { null }
      )

      fun validatePassword(password: Text): String? = Password(password.value ?: "").fold(
        { I18n.tr("Errors.Password.Invalid") },
        { null }
      )
    }
  }

  init {
    require("./css/pages/login/sections/login-form.css")

    addCustom(
      Credentials::email,
      labodocText {
        label = I18n.tr("LoginForm.Email")
        addCssClass("email")
        input.autocomplete = Autocomplete.USERNAME
      },
      required = true,
      validatorMessage = { Credentials.validateEmail(it) },
      validator = { Credentials.validateEmail(it) == null }
    )
    addCustom(
      Credentials::password,
      labodocText {
        type = InputType.PASSWORD
        label = I18n.tr("LoginForm.Password")
        addCssClass("password")
        input.autocomplete = Autocomplete.CURRENT_PASSWORD

        button(I18n.tr("LoginForm.ForgottenPassword"), className = "forgot-password") {
          onClick {
            loginPageState.setState(LoginPage.State.PasswordForgottenForm)
          }
        }
      },
      required = true,
      validatorMessage = { Credentials.validatePassword(it) },
      validator = { Credentials.validatePassword(it) == null }
    )

    add(
      Credentials::rememberMe,
      labodocCheckbox {
        label = I18n.tr("LoginForm.RememberMe")
        addCssClass("remember-me")
      }
    )

    labodocButton(I18n.tr("LoginForm.Login"), className = "login") {
      addCssClass("labodoc-background-yellow")
      onClick {
        if (this@LoginForm.validate()) {
          val formData = this@LoginForm.getData()
          App.scope.launch {
            userRepository.login(formData.email, formData.password)
              .onLeft { error ->
                when (error.code) {
                  Errors.Authentication.InvalidCredentials.code -> Toast.danger("Indentifiants invalides ou inconnus")
                  else -> Toast.danger("Une erreur est survenue, veuillez réessayer")
                }
              }
              .onRight {
                Cookie.set(Cookies.AuthToken, it, Cookie.Options(expires = if (formData.rememberMe) 31 else null))

                userRepository.getSelf()
                  .onRight { user ->
                    App.user.setState(user)

                    if (redirectTo != null)
                      App.routing.navigate(redirectTo)
                    else {
                      val page = when (user) {
                        is AdminUserModel -> Page.AdminBackOfficeLaboratoriesList()
                        is HealthProfessionalUserModel -> Page.HealthProfessionalDashboard()
                      }
                      App.routing.navigate(page)
                    }
                  }
                  .onLeft {
                    Toast.danger("Une erreur est survenue, veuillez réessayer")
                  }
              }
          }
        }
      }
    }
  }
}
