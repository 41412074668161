package fr.labodoc.webapp.pages.admin.users

import fr.labodoc.app.data.admin.model.FreestandingApplicationModel
import fr.labodoc.app.data.admin.repository.ApplicationsRepository
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminUserApplicationsPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading: UiState()

      data object Error: UiState()

      data class Loaded(
        val applications: ObservableList<FreestandingApplicationModel>
      ): UiState()
    }

    val uiState: ObservableState<UiState>

    fun validateApplication(application: FreestandingApplicationModel)

    fun rejectApplication(application: FreestandingApplicationModel)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val applicationsRepository: ApplicationsRepository by inject(named("admin"))

    private val applications: ObservableListWrapper<FreestandingApplicationModel> =
      ObservableListWrapper()

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        val newUiState = applicationsRepository
          .getPendingFreestandingApplications()
          .fold(
            {
              ViewModel.UiState.Error
            },
            {
              applications.addAll(it)

              ViewModel.UiState.Loaded(
                applications = applications
              )
            }
          )

        observableValue.setState(newUiState)
      }

      observableValue
    }

    override fun validateApplication(application: FreestandingApplicationModel) {
      App.scope.launch {
        applicationsRepository
          .validateFreestandingPendingApplication(application.id)
          .onLeft {
            Toast.danger("Une erreur est survenue lors de la validation de la demande d'inscription")
          }
          .onRight {
            applications.remove(application)
          }
      }
    }

    override fun rejectApplication(application: FreestandingApplicationModel) {
      App.scope.launch {
        applicationsRepository
          .rejectFreestandingPendingApplication(application.id)
          .onLeft {
            Toast.danger("Une erreur est survenue lors du refus de la demande d'inscription")
          }
          .onRight {
            applications.remove(application)
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-users-applications"
    require("./css/pages/admin/users/applications.css")

    div(className = "page-width") {
      header {
        h1("Liste des demandes d'inscription")
      }

      div(className = "container").bind(viewModel.uiState) { uiState ->
        when (uiState) {
          ViewModel.UiState.Loading -> {
            labodocSpinner()
          }

          ViewModel.UiState.Error -> {
            p("Impossible de récupérer la liste des demandes d'inscription")
          }

          is ViewModel.UiState.Loaded -> {
            div(className = "users-applications-cards").bindEach(uiState.applications) { application ->
              div(className = "user-application-card") {
                div(className = "information") {
                  div(className = "category personal") {
                    p(className = "title") {
                      rich = true
                      content = """<i class="fa-solid fa-user"></i> <b>Informations personnelles</b>"""
                    }

                    p {
                      rich = true
                      content = "<b>Nom</b>:<br>${application.lastName.value}"
                    }

                    p {
                      rich = true
                      content = "<b>Prénom</b>:<br>${application.firstName.value}"
                    }

                    p {
                      rich = true
                      content =
                        """<b><i class="fa-solid fa-phone"></i> Téléphone</b>:<br><a href="tel:${application.phone.value}">${application.phone.value}</a>"""
                    }

                    p {
                      rich = true
                      content =
                        """<b><i class="fa-solid fa-envelope"></i> Email</b>:<br><a href="mailto:${application.email.value}">${application.email.value}"""
                    }
                  }

                  div(className = "category professional") {
                    p(className = "title") {
                      rich = true
                      content = """<i class="fa-solid fa-user-doctor"></i> <b>Informations professionnelles</b>"""
                    }

                    p {
                      rich = true
                      content = "<b>Profession</b>:<br>${application.medicalProfession.value}"
                    }

                    p {
                      rich = true
                      content = "<b>Spécialité</b>:<br>${application.medicalSpeciality.value}"
                    }

                    p {
                      rich = true
                      content = "<b>Justificatif</b>:<br>"
                      link("Consulter le document", application.supportingDocumentUrl.toString(), target = "_blank")
                    }
                  }

                  div(className = "category facility") {
                    p(className = "title") {
                      rich = true
                      content = """<i class="fa-solid fa-house-medical"></i> <b>Structure</b>"""
                    }

                    p {
                      rich = true
                      content = "<b>Nom</b>:<br>${application.facility.name}"
                    }

                    p {
                      rich = true
                      content =
                        "<b>Ville</b>:<br>${application.facility.departmentCode.value} - ${application.facility.city}"
                    }

                    p {
                      rich = true
                      content = "<b>Service</b>:<br>${application.facility.unit}"
                    }
                  }

                  div(className = "category complementary") {
                    p(className = "title") {
                      rich = true
                      content = """<i class="fa-solid fa-circle-info"></i> <b>Informations complémentaires</b>"""
                    }

                    p {
                      rich = true
                      content = "<b>Code partenaire utilisé</b>:<br>${application.partnerCode?.value ?: "/"}"
                    }

                    p {
                      rich = true
                      content = "<b>Commentaire</b>:<br>${application.comment?.replace("\n", "<br>") ?: "/"}"
                    }
                  }
                }

                div(className = "actions") {
                  labodocButton("Valider", "fa-solid fa-check", className = "validate") {
                    onClick {
                      viewModel.validateApplication(application)
                    }
                  }

                  labodocButton("Refuser", "fa-solid fa-ban", className = "reject") {
                    onClick {
                      viewModel.rejectApplication(application)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.adminUserApplicationsPage(): AdminUserApplicationsPage {
  val adminUserApplicationsPage = AdminUserApplicationsPage()

  this.add(adminUserApplicationsPage)
  return adminUserApplicationsPage
}
