package fr.labodoc.webapp.pages.login

import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import fr.labodoc.webapp.pages.login.sections.LoginForm
import fr.labodoc.webapp.pages.login.sections.PasswordForgottenForm
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind

class LoginPage(redirectTo: String?) : SimplePanel() {
  enum class State {
    LoginForm,
    PasswordForgottenForm,
    PasswordForgottenSuccess
  }

  val state: MutableState<State> = ObservableValue(State.LoginForm)

  init {
    require("./css/pages/login/login.css")

    id = "page-login"

    div(className = "page-width") {
      i(className = "icon fa-solid fa-user")

      div(className = "container").bind(state) {
        h1 {
          content = "Connexion"
        }

        hr()

        when (it) {
          State.LoginForm -> {
            p(rich = true) {
              content = "Vous n'avez pas encore de compte ? "
              navigoLink("Inscrivez-vous gratuitement", Page.Register())
            }

            val form = LoginForm(redirectTo, state)

            add(form)
          }

          State.PasswordForgottenForm -> {
            p {
              strong("Pour réinitialiser votre mot de passe, renseignez votre adresse mail")
            }

            add(PasswordForgottenForm(state))
          }

          State.PasswordForgottenSuccess -> {
            p {
              content = "Un message avec un lien pour changer votre mot de passe a été envoyé sur votre mail !"
            }

            navigoLink("", Page.Home()) {
              labodocButton("Retourner à l'accueil", className = "labodoc-background-yellow")
            }
          }
        }
      }
    }
  }
}

fun Container.loginPage(redirectTo: String?) {
  add(LabodocHeader())
  add(LoginPage(redirectTo))
  add(LabodocFooter())
}
