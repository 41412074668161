package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import io.ktor.http.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class SelfResponse {
  @Serializable
  @SerialName("health")
  data class HealthProfessional(
    val emailAddress: EmailAddressAsString,
    val rppsNumber: RPPSNumberAsString?,
    val firstName: FirstNameAsString,
    val lastName: LastNameAsString,
    val department: Department,
    val medicalProfession: MedicalProfession,
    val medicalSpeciality: MedicalSpeciality,
    val medicalInterests: Set<MedicalInterest>,
    val canHaveMedicalInterests: Boolean,
    val professionalCategory: ProfessionalCategory,
    val professionalStatus: ProfessionalStatus,
    val dailyNotificationEnabled: Boolean,
    val weeklyNotificaitonEnabled: Boolean
  ) : SelfResponse() {
    @Serializable
    data class Department(
      val code: DepartmentCodeAsString,
      val name: DepartmentNameAsString
    )

    @Serializable
    data class MedicalProfession(
      val id: MedicalProfessionIdAsString,
      val name: MedicalProfessionNameAsString
    )

    @Serializable
    data class MedicalSpeciality(
      val id: MedicalSpecialityIdAsString,
      val name: MedicalSpecialityNameAsString,
      val partnership: Partnership?
    ) {
      @Serializable
      data class Partnership(
        val name: PartnershipNameAsString,
        val logo: UrlAsString,
        val website: PartnershipWebsiteAsString
      )
    }

    @Serializable
    data class MedicalInterest(
      val id: MedicalInterestIdAsString,
      val name: MedicalInterestNameAsString
    )

    @Serializable
    data class ProfessionalCategory(
      val code: ProfessionalCategoryCodeAsString,
      val name: ProfessionalCategoryLabelAsString
    )

    @Serializable
    data class ProfessionalStatus(
      val code: ProfessionalStatusCodeAsString,
      val name: ProfessionalStatusLabelAsString
    )
  }

  @Serializable
  @SerialName("admin")
  data class Admin(
    val emailAddress: EmailAddressAsString,
  ) : SelfResponse()
}
