package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.HealthProfessionalResponse
import fr.labodoc.api.payloads.responses.SelfResponse
import fr.labodoc.app.data.healthprofessional.model.AdminUserModel
import fr.labodoc.app.data.healthprofessional.model.HealthDirectoryHealthProfessionalModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.model.UserModel
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId

class UsersRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : UsersRepository {
  override suspend fun login(email: EmailAddress, password: Password): ApiResponse<String> =
    apiClient.users
      .login(LoginRequest(email, password))
      .map { it.token }

  override suspend fun logout(): ApiResponse<Unit> =
    apiClient.users
      .logout()

  override suspend fun sendPasswordResetRequest(email: EmailAddress): ApiResponse<Unit> =
    apiClient.users
      .sendPasswordResetRequest(
        payload = PasswordResetRequest(
          emailAddress = email
        )
      )

  override suspend fun finalizePasswordResetRequest(token: String, password: Password): ApiResponse<Unit> =
    apiClient.users
      .finalizePasswordResetRequest(
        token = token,
        payload = FinalizePasswordResetRequest(
          password = password
        )
      )

  override suspend fun changePassword(previousPassword: Password, newPassword: Password): ApiResponse<Unit> =
    apiClient.users
      .changePassword(ChangePasswordRequest(previousPassword, newPassword))

  override suspend fun getSelf(): ApiResponse<UserModel> =
    apiClient.users
      .getSelf()
      .map(SelfResponse::toModel)

  override suspend fun updateMedicalInterests(medicalInterests: Set<MedicalInterestId>): ApiResponse<Unit> =
    apiClient.users
      .updateMedicalInterests(
        payload = UpdateSelfMedicalInterestsRequest(
          medicalInterests = medicalInterests,
        )
      )

  override suspend fun updateNotificationsSettings(
    dailyNotificationEnabled: Boolean,
    weeklyNotificationEnabled: Boolean
  ): ApiResponse<Unit> =
    apiClient.users
      .updateNotificationsSettings(
        payload = UpdateSelfNotificationsSettingsRequest(
          dailyNotificationEnabled = dailyNotificationEnabled,
          weeklyNotificationEnabled = weeklyNotificationEnabled
        )
      )

  override suspend fun getHealthProfessionalByCardNumber(cardNumber: MedicalCardNumber): ApiResponse<HealthDirectoryHealthProfessionalModel> =
    apiClient.applications
      .getHealthProfessionalByCardNumber(cardNumber)
      .map(HealthProfessionalResponse::toModel)

  override suspend fun registerWithMedicalCardCPS(
    rppsNumber: RPPSNumber?,
    medicalCardNumber: MedicalCardNumber,
    firstName: FirstName,
    lastName: LastName,
    healthDirectoryProfession: ProfessionCode,
    healthDirectoryProfessionalCategory: ProfessionalCategoryCode,
    healthDirectoryProfessionalStatus: ProfessionalStatusCode,
    healthDirectoryExpertise: ExpertiseCode?,
    healthDirectoryPharmacistSection: PharmacistTableSection.Code?,
    department: DepartmentCode,
    dailyNotificationEnabled: Boolean,
    weeklyNotificationEnabled: Boolean,
    email: EmailAddress,
    password: Password,
    partnerCode: PartnerCode?
  ): ApiResponse<Unit> =
    apiClient.applications
      .registerWithMedicalCardCPS(
        payload = MedicalCardCPSRegistrationRequest(
          rppsNumber = rppsNumber,
          medicalCardNumber = medicalCardNumber,
          firstName = firstName,
          lastName = lastName,
          professionCode = healthDirectoryProfession,
          professionalCategoryCode = healthDirectoryProfessionalCategory,
          professionalStatusCode = healthDirectoryProfessionalStatus,
          expertiseCode = healthDirectoryExpertise,
          pharmacistSectionCode = healthDirectoryPharmacistSection,
          departmentCode = department,
          dailyNotificationEnabled = dailyNotificationEnabled,
          weeklyNotificationEnabled = weeklyNotificationEnabled,
          email = email,
          password = password,
          partnerCode = partnerCode
        )
      )

  override suspend fun registerWithMedicalCardCPF(
    rppsNumber: RPPSNumber?,
    medicalCardNumber: MedicalCardNumber,
    firstName: FirstName,
    lastName: LastName,
    healthDirectoryProfession: ProfessionCode,
    medicalDiploma: MedicalDiplomaId,
    universityHospital: UniversityHospitalId,
    dailyNotificationEnabled: Boolean,
    weeklyNotificationEnabled: Boolean,
    email: EmailAddress,
    password: Password,
    partnerCode: PartnerCode?
  ): ApiResponse<Unit> =
    apiClient.applications
      .registerWithMedicalCardCPF(
        payload = MedicalCardCPFRegistrationRequest(
          rppsNumber = rppsNumber,
          medicalCardNumber = medicalCardNumber,
          firstName = firstName,
          lastName = lastName,
          professionCode = healthDirectoryProfession,
          medicalDiplomaId = medicalDiploma,
          universityHospitalId = universityHospital,
          dailyNotificationEnabled = dailyNotificationEnabled,
          weeklyNotificationEnabled = weeklyNotificationEnabled,
          email = email,
          password = password,
          partnerCode = partnerCode
        )
      )

  override suspend fun registerWithoutMedicalCard(
    rppsNumber: RPPSNumber?,
    firstName: FirstName,
    lastName: LastName,
    phoneNumber: PhoneNumber,
    medicalSpeciality: MedicalSpecialityId,
    facilityName: String,
    facilityUnit: String,
    facilityCity: String,
    facilityDepartment: DepartmentCode,
    supportingDocument: InputFile,
    dailyNotificationEnabled: Boolean,
    weeklyNotificationEnabled: Boolean,
    emailAddress: EmailAddress,
    password: Password,
    comment: String?,
    partnerCode: PartnerCode?
  ): ApiResponse<Unit> =
    apiClient.applications
      .registerWithoutMedicalCard(
        payload = NoMedicalCardRegistration(
          rppsNumber = rppsNumber,
          firstName = firstName,
          lastName = lastName,
          phoneNumber = phoneNumber,
          medicalSpeciality = medicalSpeciality,
          professionalCategoryCode = ProfessionalCategoryCode("C"),
          professionalStatusCode = ProfessionalStatusCode("S"),
          facilityName = facilityName,
          facilityUnit = facilityUnit,
          facilityCity = facilityCity,
          facilityDepartmentCode = facilityDepartment,
          dailyNotificationEnabled = dailyNotificationEnabled,
          weeklyNotificationEnabled = weeklyNotificationEnabled,
          emailAddress = emailAddress,
          password = password,
          comment = comment,
          partnerCode = partnerCode
        ),
        supportingDocument = supportingDocument
      )

  override suspend fun confirmEmail(token: String): ApiResponse<Unit> =
    apiClient.applications
      .validateApplicationEmail(token)
}

fun SelfResponse.toModel(): UserModel =
  when (this) {
    is SelfResponse.Admin -> AdminUserModel(
      emailAddress = emailAddress
    )

    is SelfResponse.HealthProfessional -> HealthProfessionalUserModel(
      emailAddress = emailAddress,
      rppsNumber = rppsNumber,
      firstName = firstName,
      lastName = lastName,
      department = HealthProfessionalUserModel.Department(
        code = department.code,
        name = department.name
      ),
      medicalProfession = HealthProfessionalUserModel.MedicalProfession(
        id = medicalProfession.id,
        name = medicalProfession.name
      ),
      medicalSpeciality = HealthProfessionalUserModel.MedicalSpeciality(
        id = medicalSpeciality.id,
        name = medicalSpeciality.name,
        partnership = medicalSpeciality.partnership?.let { partnership ->
          HealthProfessionalUserModel.MedicalSpeciality.Partnership(
            name = partnership.name,
            logo = partnership.logo,
            website = partnership.website
          )
        }
      ),
      medicalInterests = medicalInterests
        .map {
          HealthProfessionalUserModel.MedicalInterest(
            id = it.id,
            name = it.name
          )
        }
        .toSet(),
      canHaveMedicalInterests = canHaveMedicalInterests,
      professionalCategory = HealthProfessionalUserModel.ProfessionalCategory(
        code = professionalCategory.code,
        name = professionalCategory.name
      ),
      professionalStatus = HealthProfessionalUserModel.ProfessionalStatus(
        code = professionalStatus.code,
        name = professionalStatus.name
      ),
      dailyNotificationEnabled = dailyNotificationEnabled,
      weeklyNotificationEnabled = weeklyNotificaitonEnabled
    )
  }

fun HealthProfessionalResponse.toModel(): HealthDirectoryHealthProfessionalModel =
  HealthDirectoryHealthProfessionalModel(
    nationalIdentifier = nationalIdentifier,
    medicalCard = HealthDirectoryHealthProfessionalModel.MedicalCard(
      number = medicalCard.number,
      typeCode = medicalCard.typeCode,
      typeLabel = medicalCard.typeLabel
    ),
    practices = practices.map { practice ->
      HealthDirectoryHealthProfessionalModel.Practice(
        practiceFirstName = practice.practiceFirstName,
        practiceLastName = practice.practiceLastName,
        professionCode = practice.professionCode,
        professionName = practice.professionLabel,
        professionalCategoryCode = practice.professionalCategoryCode,
        professionalCategoryName = practice.professionalCategoryLabel,
        expertises = practice.expertises.map { expertise ->
          HealthDirectoryHealthProfessionalModel.Practice.Expertise(
            code = expertise.code,
            name = expertise.name
          )
        }.toSet(),
        situations = practice.situations.map { situation ->
          HealthDirectoryHealthProfessionalModel.Practice.Situation(
            professionalStatusCode = situation.professionalStatusCode,
            professionalStatusName = situation.professionalStatusLabel,
            pharmacistTableSectionCode = situation.pharmacistSectionCode,
            facility = situation.facility?.let { facility ->
              HealthDirectoryHealthProfessionalModel.Practice.Situation.Facility(
                name = facility.name,
                officeCedex = facility.officeCedex,
                departmentCode = facility.departmentCode
              )
            }
          )
        }.toSet()
      )
    }.toSet()
  )
