package fr.labodoc.webapp.pages.home

import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.payloads.serializers.PasswordAsString
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.application.Application
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.layouts.LabodocFooter
import io.kvision.core.Container
import io.kvision.form.formPanel
import io.kvision.form.text.Text
import io.kvision.html.*
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import io.kvision.i18n.I18n.tr as translation

class HomePage(popup: Page.Home.Popup?) : SimplePanel(), KoinComponent {
  private val userRepository: UsersRepository by inject()

  init {
    id = "page-home"
    require("./css/pages/home/home.css")

    when (popup) {
      is Page.Home.Popup.RegistrationSuccessful -> { // For the moment success is always considered to be true if set
        LabodocPopup(
          closeButton = true,
          icon = "fa-solid fa-triangle-exclamation",
          className = "registration-successful",
          content = {
            p(className = "title") {
              rich = true
              content = "<b>Votre inscription est presque terminée !</b>"
            }
            p(className = "text") {
              rich = true
              content = """
              <b>Consultez votre boite mail pour confirmer votre inscription.<br>
              Un simple clic, et vous serez prêt à explorer LaboDoc.</b><br>
              <br>
              Vous n’avez pas reçu de mail ? vérifiez vos spams, il pourrait s’y trouver.<br>
              <br>
              Besoin d’aide ? <b><a href="${Page.Contact().url}">Contactez-nous !</a></b>
            """.trimIndent()
            }
          }
        ).show()
      }

      is Page.Home.Popup.RegistrationConfirmEmail -> {
        App.scope.launch {

          userRepository.confirmEmail(popup.token)
            .onLeft {
              LabodocPopup(
                closeButton = true,
                icon = "fa-solid fa-triangle-exclamation",
                className = "registration-email-confirmation",
                content = {
                  p(className = "title") {
                    rich = true
                    content = "<b>Votre email n'a pas pu être correctement vérifié !</b>"
                  }
                  p(className = "text") {
                    rich = true
                    content = """
                    Besoin d’aide ? <b><a href="${Page.Contact().url}">Contactez-nous !</a></b>
                  """.trimIndent()
                  }
                }
              ).show()
            }
            .onRight {
              LabodocPopup(
                closeButton = true,
                icon = "fa-solid fa-circle-check",
                className = "registration-email-confirmation",
                content = {
                  p(className = "title") {
                    rich = true
                    content = "<b>Votre email est correctement vérifié !</b>"
                  }
                  p(className = "text") {
                    rich = true
                    content = """
                    <b>Vous êtes maintenant prêt à explorer LaboDoc !</b><br>
                    <br>
                    Besoin d’aide ? <b><a href="${Page.Contact().url}">Contactez-nous !</a></b>
                  """.trimIndent()
                  }
                }
              ).show()
            }
        }
      }

      is Page.Home.Popup.ApplicationSuccessful -> { // For the moment success is always considered to be true if set
        LabodocPopup(
          closeButton = true,
          icon = "fa-solid fa-circle-check",
          className = "application-successful",
          content = {
            p(className = "title") {
              rich = true
              content = "<b>Votre demande d’inscription est presque terminée !</b>"
            }
            p(className = "text") {
              rich = true
              content = """
              <b>Consultez votre boite mail pour valider votre email et confirmer votre demande d'inscription.</b><br>
              <br>
              Vous n’avez pas reçu de mail ? vérifiez vos spams, il pourrait s’y trouver.<br>
              <br>
              Besoin d’aide ? <b><a href="${Page.Contact().url}">Contactez-nous !</a></b>
            """.trimIndent()
            }
          }
        ).show()
      }

      is Page.Home.Popup.ApplicationConfirmEmail -> {
        App.scope.launch {
          userRepository.confirmEmail(popup.token)
            .onLeft {
              LabodocPopup(
                closeButton = true,
                icon = "fa-solid fa-triangle-exclamation",
                className = "application-email-confirmation",
                content = {
                  p(className = "title") {
                    rich = true
                    content = "<b>Votre email n'a pas pu être correctement vérifié !</b>"
                  }
                  p(className = "text") {
                    rich = true
                    content = """
                    Besoin d’aide ? <b><a href="${Page.Contact().url}">Contactez-nous !</a></b>
                  """.trimIndent()
                  }
                }
              ).show()
            }
            .onRight {
              LabodocPopup(
                closeButton = true,
                icon = "fa-solid fa-circle-check",
                className = "application-email-confirmation",
                content = {
                  p(className = "title") {
                    rich = true
                    content = "<b>Votre email est correctement vérifié !</b>"
                  }
                  p(className = "text") {
                    rich = true
                    content = """
                    <b>Nous allons étudier votre demande d'inscription dans les plus brefs délais.<br>
                    Vous recevrez un email de confirmation d'inscription.</b><br>
                    <br>
                    Besoin d’aide ? <b><a href="${Page.Contact().url}">Contactez-nous !</a></b>
                  """.trimIndent()
                  }
                }
              ).show()
            }
        }
      }

      is Page.Home.Popup.ResetPassword -> {
        @Serializable
        data class FormData(
          val password: PasswordAsString,
          val passwordConfirmation: PasswordAsString
        )

        LabodocPopup(
          closeButton = true,
          icon = "fa-solid fa-user",
          className = "reset-password",
          content = { labodocPopup ->
            p(className = "title") {
              rich = true
              content = "<b>Modifiez votre mot de passe</b>"
            }
            val form = formPanel<FormData> {
              fun validatePassword(password: String?): String? = Password(password ?: "").fold(
                {
                  when (it) {
                    Errors.Password.Invalid.TooShort -> "Trop court"
                    Errors.Password.Invalid.TooLong -> "Trop long"
                    Errors.Password.Invalid.NoLowercaseLetter -> "Pas de minuscule"
                    Errors.Password.Invalid.NoUppercaseLetter -> "Pas de majuscule"
                    Errors.Password.Invalid.NoDigit -> "Pas de chiffre"
                    Errors.Password.Invalid.NoSpecialCharacter -> "Pas de caractère special"
                  }
                },
                { null }
              )

              fun validateSamePassword(password: String?, passwordConfirmation: String?): String? {
                return if (password != passwordConfirmation)
                  "Les mots de passe ne sont pas identiques"
                else
                  null
              }

              val passwordField = labodocText {
                type = InputType.PASSWORD
                input.autocomplete = Autocomplete.NEW_PASSWORD
                label = "Mot de passe"
                notice = "Doit contenir entre 8 et 32 caractères avec au moins:<ul><li>une minuscule</li><li>une majuscule<li>un chiffre</li><li>caractère spécial</li>"
                noticeRich = true
              }.bindCustom(
                FormData::password,
                required = true,
                requiredMessage = I18n.tr("Field.Required"),
                validator = { validatePassword(it.value) == null },
                validatorMessage = { validatePassword(it.value) }
              )

              val passwordConfirmationField = labodocText {
                type = InputType.PASSWORD
                input.autocomplete = Autocomplete.NEW_PASSWORD // https://github.com/whatwg/html/issues/3531
                label = "Confirmez votre mot de passe"
              }.bindCustom(
                FormData::passwordConfirmation,
                required = true,
                requiredMessage = I18n.tr("Field.Required"),
                validator = { validateSamePassword(passwordField.value, it.value) == null },
                validatorMessage = {validateSamePassword(passwordField.value, it.value) },
              )

            }
            labodocButton("Valider") {
              onClick {
                if (form.validate()) {
                  App.scope.launch {
                    userRepository.finalizePasswordResetRequest(
                      token = popup.token,
                      password = form.getData().password
                    )
                      .onLeft { error ->
                        val errorMessage = when(error.code) {
                          "Repository.Expired" -> "Le lien n'est plus valide, veuillez faire une nouvelle demande"
                          else -> "Une erreur est survenue"
                        }
                        Toast.danger(errorMessage)
                      }
                      .onRight {
                        Toast.success("Mot de passe correctement mis à jour")
                        labodocPopup.hide()
                      }
                  }
                }
              }
            }
          }
        ).show()
      }

      null -> {}
    }

    div(className = "labodoc-background-beige") {
      id = "page-home-header"

      div(className = "labodoc-background-middle-blue") {
        id = "page-home-presentation"

        div(className = "page-width") {
          id = "page-home-presentation-content"

          image(require("./img/Labodoc-logotype-horizontal-blanc.svg"), alt = "LaboDoc")

          h1 {
            content = translation("Home.Presentation.Title")
          }

          p {
            content = """
              Spécialement conçue pour les professionnels de santé, notre application centralise et personnalise l’accès aux informations pharmaceutiques. Gagnez un temps précieux avec un accès rapide aux dernières avancées, formations et ressources essentielles !
            """.trimIndent()
          }

          navigoLink("", url = "${Page.Home().url}#page-home-pros") {
            labodocButton(translation("Home.Presentation.DiscoverButton"), className = "labodoc-background-light-blue")
          }

          div {
            id = "home-presentation-mobile"
            image(require("./img/apple-store.png"))
            image(require("./img/google-play.png"))
          }
        }
      }

      div {
        id = "page-home-login"

        div(className = "page-width") {
          h1 {
            content = I18n.tr("LoginForm.Title")
          }

          hr()

          p {
            content = "Inscrivez-vous gratuitement et en quelques minutes seulement !"
          }

          navigoLink("", Page.Register()) {
            labodocButton("S'inscrire gratuitement", className = "labodoc-background-yellow")
          }

          navigoLink("", Page.Login()) {
            labodocButton("Se connecter", className = "labodoc-background-middle-blue-reverse ")
          }
        }
      }
    }

    div(className = "labodoc-background-beige") {
      id = "page-home-pros"

      div(className = "page-width") {
        h1 {
          content = """
            LaboDoc, l'application qui centralise la communication des laboratoires pharmaceutiques !
          """.trimIndent()
        }

        div(className = "desktop") {
          div(className = "bubble labodoc-background-white") {
            h2 {
              content = translation("Home.Pros.Security.Title")
            }

            hr()

            p {
              rich = true
              content = """
                LaboDoc diffuse exclusivement des <b>documents sourcés</b>, <b>officiels</b> et en parfaite <b>conformité</b> avec la législation en vigueur (visa ANSM, agréments). Vos données personnelles et votre anonymat sont protégés.
              """.trimIndent()
            }
          }

          div(className = "bubble labodoc-background-white") {
            h2 {
              content = "Veille personnalisée"
            }

            hr()

            p {
              rich = true
              content = """
                Ni spam, ni mails intempestifs ! LaboDoc vous permet de définir des filtres précis pour ne voir que les <b>informations essentielles à votre pratique</b>, le tout, à votre rythme. Un contrôle total pour une <b>veille continue, sur-mesure et sans effort</b>.
              """.trimIndent()
            }
          }

          div(className = "bubble labodoc-background-white") {
            h2 {
              content = "Gain de temps assuré"
            }

            hr()

            p {
              rich = true
              content = """
                LaboDoc offre un accès centralisé à l'actualité diffusée par les laboratoires partenaires de votre spécialité. L'information est constamment <b>mise à jour, triée et archivée</b> vous permettant de rester parfaitement informé(e) quand vous le voulez (24h/7j).
              """.trimIndent()
            }
          }
        }
      }

      div(className = "mobile swiper") {
        swiperContainer(className = "swiper-wrapper") {
          setAttribute("slides-per-view", "auto")
          setAttribute("centered-slides", "true")
          setAttribute("pagination", "true")
          setAttribute("pagination-dynamic-bullets", "true")

          slide(className = "bubble labodoc-background-white swiper-slide") {
            h2 {
              content = translation("Home.Pros.Security.Title")
            }

            hr()

            p {
              rich = true
              content = """
                LaboDoc diffuse exclusivement des <b>documents sourcés</b>, <b>officiels</b> et en parfaite <b>conformité</b> avec la législation en vigueur (visa ANSM, agréments). Vos données personnelles et votre anonymat sont protégés.
              """.trimIndent()
            }
          }

          slide(className = "bubble labodoc-background-white") {
            h2 {
              content = "Veille personnalisée"
            }

            hr()

            p {
              rich = true
              content = """
                Ni spam, ni mails intempestifs ! LaboDoc vous permet de définir des filtres précis pour ne voir que les <b>informations essentielles à votre pratique</b>, le tout, à votre rythme. Un contrôle total pour une <b>veille continue, sur-mesure et sans effort</b>.
              """.trimIndent()
            }
          }

          slide(className = "bubble labodoc-background-white swiper-slide") {
            h2 {
              content = "Gain de temps assuré"
            }

            hr()

            p {
              rich = true
              content = """
                LaboDoc offre un accès centralisé à l'actualité diffusée par les laboratoires partenaires de votre spécialité. L'information est constamment <b>mise à jour, triée et archivée</b> vous permettant de rester parfaitement informé(e) quand vous le voulez (24h/7j).
              """.trimIndent()
            }
          }
        }
      }
    }

    div(className = "labodoc-background-white") {
      id = "page-home-features"

      div(className = "page-width") {
        div {
          h1 {
            content = translation("Home.Features.Title")
          }

          listTag(
            ListType.UL,
            listOf(
              "Les <b>invitations</b> aux réunions de formation et webinaire en lien avec votre spécialité",
              "Les <b>flash info</b> que les laboratoires souhaitent porter à votre connaissance",
              "Les <b>fiches posologiques</b> et autres <b>supports d’information</b> utiles à votre pratique",
              "La <b>mise en relation directe</b> avec un laboratoire dès que vous en avez besoin",
              "Un annuaire des <b>acteurs de la santé</b> (Sociétés savantes, HAS, ANSM, ...)",
              "Et bien d’autres ressources essentielles !"
            ),
            rich = true
          )
        }

        image(require("./img/page-home-doctor.jpg"))
      }
    }

    div(className = "labodoc-background-white") {
      id = "page-home-mission"

      div(className = "page-width") {
        div {
          h1 {
            content = translation("Home.Mission.Title")
          }

          hr()

          p {
            rich = true
            content = """
              Notre mission chez LaboDoc est de rendre l'accès à l'information médicale aussi simple et efficace que possible, contribuant ainsi à <b>l'amélioration de la prise en charge des patients et au bon usage des médicaments</b>.<br>
              <br>
              Nous nous efforçons de vous fournir une plateforme <b>innovante, personnalisée et respectueuse de l’environnement</b>, qui répond à vos besoins en constante évolution. Nos objectifs : plus de réactivité et moins de gaspillage (papiers, CO2, temps perdu).
            """.trimIndent()
          }
        }
        navigoLink("", Page.Register()) {
          labodocButton(translation("Home.Mission.Button"), className = "labodoc-background-yellow")
        }
      }
    }

    div(className = "labodoc-background-beige") {
      id = "page-home-testimonials"

      div(className = "page-width") {
        h1 {
          content = translation("Home.Testimonials.Title")
        }

        p {
          content = translation("Home.Testimonials.SubTitle")
        }
      }

      swiperContainer {
        setAttribute("slides-per-view", "auto")
        setAttribute("centered-slides", "true")
        setAttribute("loop", "true")
        setAttribute("pagination", "true")
        setAttribute("pagination-dynamic-bullets", "true")

        slide(className = "testimonial labodoc-background-white") {
          p(className = "text") {
            content =
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum leo non orci luctus, non tempus magna pharetra."
          }

          p(className = "author") {
            content = "Christopher P."
          }

          p(className = "author-information") {
            content = "Grand dieu, Dijon"
          }
        }

        slide(className = "testimonial labodoc-background-white swiper-slide") {
          p(className = "text") {
            content =
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum leo non orci luctus, non tempus magna pharetra."
          }

          p(className = "author") {
            content = "Christopher P."
          }

          p(className = "author-information") {
            content = "Grand dieu, Dijon"
          }
        }

        slide(className = "testimonial labodoc-background-white swiper-slide") {
          p(className = "text") {
            content =
              "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc rutrum leo non orci luctus, non tempus magna pharetra."
          }

          p(className = "author") {
            content = "Christopher P."
          }

          p(className = "author-information") {
            content = "Grand dieu, Dijon"
          }
        }
      }
    }

    val swiper = require("swiper/element/bundle")

    swiper.register()
  }
}

fun Container.homePage(
  popup: Page.Home.Popup?
) {
  add(HomePage(popup))
  add(LabodocFooter())
}
