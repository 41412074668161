package fr.labodoc.webapp.pages.healthProfessional.profile

import fr.labodoc.app.data.healthprofessional.model.AdminUserModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.model.UserModel
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocRadioGroup
import fr.labodoc.webapp.components.labodocSpinner
import io.kvision.core.Container
import io.kvision.form.check.radio
import io.kvision.html.div
import io.kvision.html.p
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class HealthProfessionalProfileNotificationsSettingsPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data class Loaded(
        val dailyNotificationEnabled: Boolean,
        val weeklyNotificationEnabled: Boolean
      ) : UiState()

      data class Error(
        val message: String
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun updateNotificationsSettings(
      dailyNotificationEnabled: Boolean,
      weeklyNotificationEnabled: Boolean
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val userRepository: UsersRepository by inject()

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        userRepository.getSelf()
          .fold(
            {
              ViewModel.UiState.Error("Une erreur est survenue lors du chargement de vos données")
            },
            { user: UserModel ->
              when (user) {
                is AdminUserModel -> ViewModel.UiState.Error("Utilisateur incorrecte")

                is HealthProfessionalUserModel -> ViewModel.UiState.Loaded(
                  dailyNotificationEnabled = user.dailyNotificationEnabled,
                  weeklyNotificationEnabled = user.weeklyNotificationEnabled
                )
              }
            }
          )
          .also { uiState.setState(it) }
      }

      observableValue
    }

    override fun updateNotificationsSettings(
      dailyNotificationEnabled: Boolean,
      weeklyNotificationEnabled: Boolean
    ) {
      App.scope.launch {
        userRepository
          .updateNotificationsSettings(
            dailyNotificationEnabled = dailyNotificationEnabled,
            weeklyNotificationEnabled = weeklyNotificationEnabled
          )
          .onLeft {
            Toast.danger("Impossible de mettre à jour vos préférences de notifications")
          }
          .onRight {
            Toast.success("Préférences de notifications correctement mises à jour")
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-health-professional-profile-notifications-settings"
    require("./css/pages/healthProfessional/profile/notifications-settings.css")

    div(className = "page-width") {
      div().bind(viewModel.uiState) { uiState ->
        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          is ViewModel.UiState.Error -> {
            Toast.danger(uiState.message)
          }

          is ViewModel.UiState.Loaded -> {
            val dailyNotificationField = radio {
              name = "notification"
              label = "Je reçois une notification journalière uniquement si une actualité publiée me concerne."
              value = uiState.dailyNotificationEnabled
            }

            val weeklyNotificationField = radio {
              name = "notification"
              label = "Je reçois une notification hebdomadaire uniquement si une actualité publiée me concerne."
              value = uiState.weeklyNotificationEnabled
            }

            p(className = "title") {
              content = "Gérer vos notifications"
            }

            p(className = "text") {
              content =
                "Assurez une veille continue et sans effort grâce à la notification LaboDoc"
            }

            labodocRadioGroup {
              addCssClass("choices")

              div {
                p {
                  content = "Instantanéité :"
                }

                add(dailyNotificationField)
              }

              div {
                p {
                  content = "Équilibre :"
                }

                add(weeklyNotificationField)
              }

              div {
                p {
                  content = "Aucune :"
                }

                radio {
                  name = "notification"
                  label = "Je préfère ne pas être informé(e) des actualités concernant les médicaments liés à ma pratique."
                  value = !dailyNotificationField.value && !weeklyNotificationField.value
                }
              }
            }

            labodocButton("Enregistrer mes préférences", className = "labodoc-background-yellow") {
              onClick {
                viewModel.updateNotificationsSettings(
                  dailyNotificationEnabled = dailyNotificationField.value,
                  weeklyNotificationEnabled = weeklyNotificationField.value
                )
              }
            }
          }
        }
      }
      p(className = "privacy-policy") {
        rich = true
        content = """
          Consultez notre <a href="${Page.PrivacyPolicy().url}" target="_blank">politique de confidentialité</a>
        """.trimIndent()
      }
    }
  }
}

fun Container.healthProfessionalNotificationsSettingsPage(): HealthProfessionalProfileNotificationsSettingsPage {
  val healthProfessionalProfileNotificationsSettingsPage = HealthProfessionalProfileNotificationsSettingsPage()
  this.add(healthProfessionalProfileNotificationsSettingsPage)
  return healthProfessionalProfileNotificationsSettingsPage
}
