package fr.labodoc.webapp.pages.login.sections

import fr.labodoc.api.payloads.serializers.EmailAddressAsString
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocText
import fr.labodoc.webapp.pages.login.LoginPage
import io.kvision.form.FormPanel
import io.kvision.form.text.Text
import io.kvision.html.Autocomplete
import io.kvision.i18n.I18n
import io.kvision.state.MutableState
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class PasswordForgottenForm(loginPageState: MutableState<LoginPage.State>) :
  FormPanel<PasswordForgottenForm.Credentials>(
    className = "password-forgotten",
    serializer = Credentials.serializer()
  ), KoinComponent {
    private val userRepository: UsersRepository by inject()

  @Serializable
  data class Credentials(
    val email: EmailAddressAsString
  ) {
    companion object {
      fun validateEmail(email: Text): String? = EmailAddress(email.value ?: "").fold(
        { I18n.tr("Errors.EmailAddress.Invalid") },
        { null }
      )
    }
  }

  init {
    require("./css/pages/login/sections/password-forgotten-form.css")

    addCustom(
      Credentials::email,
      labodocText {
        label = I18n.tr("LoginForm.Email")
        input.autocomplete = Autocomplete.USERNAME
      },
      required = true,
      validatorMessage = { Credentials.validateEmail(it) },
      validator = { Credentials.validateEmail(it) == null }
    )

    labodocButton("Envoyer l'email") {
      addCssClass("labodoc-background-yellow")
      onClick {
        if (this@PasswordForgottenForm.validate()) {
          val formData = this@PasswordForgottenForm.getData()

          App.scope.launch {
            userRepository.sendPasswordResetRequest(formData.email)
              .onLeft { Toast.danger("Une erreur est survenue") }
              .onRight { loginPageState.setState(LoginPage.State.PasswordForgottenSuccess) }
          }
        }
      }
    }

    labodocButton("Retour", className = "return") {
      onClick {
        loginPageState.setState(LoginPage.State.LoginForm)
      }
    }
  }
}
