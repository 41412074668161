package fr.labodoc.webapp.pages.admin.medicines.sections

import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.serializers.*
import fr.labodoc.app.data.admin.model.LaboratoryModel
import fr.labodoc.app.data.admin.model.MedicalInterestModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.medicine.AtcCode
import fr.labodoc.domain.labodoc.medicine.CipCode
import fr.labodoc.domain.labodoc.medicine.MedicineGovernmentPublicDatabaseId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.*
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.text.Text
import io.kvision.html.button
import io.kvision.html.div
import io.kvision.html.h3
import io.kvision.html.image
import io.kvision.i18n.I18n
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named
import org.w3c.dom.url.URL

class AdminMedicineForm(
  private val laboratories: Set<Pair<LaboratoryId, LaboratoryName>>,
  private val atcCodeCodes: Set<Pair<AtcCode.Code, AtcCode.Name>>,
  private val medicalProfessions: Set<MedicalProfessionModel>,
  private val medicineLogoUrl: Url? = null
) : FormPanel<AdminMedicineForm.Data>(serializer = Data.serializer(), className = "medicine-form") {
  private interface ViewModel {
    val selectedLaboratoryResponse: ObservableValue<ApiResponse<LaboratoryModel>?>

    val selectedLaboratory: ObservableValue<LaboratoryModel?>

    fun searchLaboratoryDetails(laboratoryId: LaboratoryId)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))

    override val selectedLaboratoryResponse: ObservableValue<ApiResponse<LaboratoryModel>?> =
      ObservableValue(null)

    override val selectedLaboratory: ObservableValue<LaboratoryModel?> =
      ObservableValue(null)

    init {
      selectedLaboratoryResponse.subscribe { selectedLaboratoryResponse ->
        selectedLaboratory.setState(selectedLaboratoryResponse?.fold({ null }, { it }))
      }
    }

    override fun searchLaboratoryDetails(laboratoryId: LaboratoryId) {
      App.scope.launch {
        laboratoriesRepository
          .getLaboratory(laboratoryId)
          .also { selectedLaboratoryResponse.setState(it) }
      }
    }
  }

  @Serializable
  data class Data(
    val laboratory: LaboratoryIdAsString? = null,
    val name: MedicineNameAsString? = null,
    val mainComposition: MedicineMainCompositionAsString? = null,
    val cipCode: CipCodeAsString? = null,
    val atcCode: AtcCodeAsString? = null,
    val governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseIdAsString? = null,
    val website: MedicineWebsiteAsString? = null,
    val logoInput: List<KFile>? = null,
    val segmentation: SegmentationAsString? = null
  ) {
    val logo: KFile?
      get() = logoInput?.first()

    companion object {
      fun validateName(name: Text): String? = MedicineName(name.value ?: "").fold(
        { error ->
          when (error) {
            Errors.Medicine.Name.Invalid.Blank -> "Ne peut pas être vide"
            Errors.Medicine.Name.Invalid.TooLong -> "Trop long"
          }
        },
        {
          null
        }
      )

      fun validateCIPCode(cipCode: Text): String? = CipCode(cipCode.value ?: "").fold(
        { error ->
          when (error) {
            Errors.CIPCode.Invalid.Format -> "Format invalide"
          }
        },
        {
          null
        }
      )

      fun validateGovernmentPublicDatabase(governmentPublicDatabase: String?): String? =
        governmentPublicDatabase?.let {
          MedicineGovernmentPublicDatabaseId(governmentPublicDatabase).fold(
            { error ->
              when (error) {
                Errors.Medicine.GovernmentPublicDatabaseId.Invalid.Format -> "Format invalide"
              }
            },
            {
              null
            }
          )
        }

      fun validateWebsite(website: Text): String? =
        website.value?.let {
          try {
            URL(it)
            null
          } catch (exception: dynamic) {
            "L'url n'est pas valide"
          }
        }

      fun validateSegmentation(segmentationInput: LabodocSegmentation): String? {
        val segmentation = segmentationInput.data

        val medicalInterestOptions: Collection<MedicalInterestModel>? =
          segmentationInput.medicalProfessions
            ?.flatMap { medicalProfession ->
              medicalProfession.medicalSpecialities
                .mapNotNull { medicalSpeciality ->
                  if (segmentation.medicalSpecialities?.contains(medicalSpeciality.id) == true)
                    medicalSpeciality.medicalInterests
                  else
                    null
                }
                .flatten()
            }
            ?.ifEmpty { null }

        return if (segmentation.medicalProfessions.isNullOrEmpty() || segmentation.medicalSpecialities.isNullOrEmpty())
          "Aucune spécialité renseignée"
        else if (medicalInterestOptions != null && segmentation.medicalInterests.isNullOrEmpty())
          "Aucun centre d'intérêt renseigné"
        else
          null
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    require("./css/pages/admin/medicines/components/medicine-form.css")

    div(className = "laboratory") {
      h3("Informations du laboratoire associé : ")

      div(className = "fields") {
        div(className = "laboratory-details") {
          div(className = "laboratory-information") {
            image(null, "Logo du laboratoire").bind(viewModel.selectedLaboratory) { selectedLaboratory ->
              val logoUrl = selectedLaboratory?.logoUrl?.toString()

              src = logoUrl
              if (logoUrl != null) show() else hide()
            }

            labodocSelect {
              label = "Laboratoire"
              options = laboratories.map { it.first.value.toString() to it.second.value }

              subscribe { value ->
                value?.let { viewModel.searchLaboratoryDetails(LaboratoryId(uuidFrom(it))) }
              }

              viewModel.selectedLaboratory.subscribe { selectedLaboratory ->
                value = selectedLaboratory?.id?.value?.toString()
              }
            }.bindCustom(
              key = Data::laboratory,
              required = true,
              requiredMessage = I18n.tr("Field.Required")
            )

            button("", icon = "fa-solid fa-close", className = "remove") {
              onClick {
                viewModel.selectedLaboratory.setState(null)
              }
            }.bind(viewModel.selectedLaboratory) { selectedLaboratory ->
              if (selectedLaboratory != null) show() else hide()
            }
          }
        }
      }
    }

    div(className = "medicine") {
      h3("Information du médicament :")

      div(className = "fields") {
        labodocText {
          label = "Nom"
        }.bindCustom(
          key = Data::name,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { Data.validateName(it) == null },
          validatorMessage = { Data.validateName(it) }
        )

        labodocText {
          label = "Composition principale"
        }.bindCustom(
          key = Data::mainComposition,
          required = true,
          requiredMessage = I18n.tr("Field.Required")
        )

        labodocText {
          label = "Code CIP"
        }.bindCustom(
          key = Data::cipCode,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { Data.validateCIPCode(it) == null },
          validatorMessage = { Data.validateCIPCode(it) }
        )

        labodocSelect {
          label = "Code ATC"
          options = atcCodeCodes.map { it.first.value to "${it.first.value} - ${it.second.value}" }
        }.bindCustom(
          key = Data::atcCode,
          required = true,
          requiredMessage = I18n.tr("Field.Required")
        )

        labodocText {
          label = "Identifiant base de données publique du médicament"
        }.bindCustom(
          key = Data::governmentPublicDatabaseId,
          required = false,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { Data.validateGovernmentPublicDatabase(it.value) == null },
          validatorMessage = { Data.validateGovernmentPublicDatabase(it.value) }
        )

        labodocText {
          label = "Site internet"
        }.bindCustom(
          key = Data::website,
          validator = { Data.validateWebsite(it) == null },
          validatorMessage = { Data.validateWebsite(it) }
        )

        labodocSegmentation(medicalProfessions, null, null, null, null) {
          label = "Segmentation"
        }.bindCustom(
          key = Data::segmentation,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { Data.validateSegmentation(it) == null },
          validatorMessage = { Data.validateSegmentation(it) }
        )
      }

      div(className = "logo") {
        val logoPreview = image(null)

        labodocUpload(label = "Logo", accept = listOf("image/*"), multiple = false) {
          subscribe { files ->
            App.scope.launch {
              logoPreview.src =
                files?.firstOrNull()?.let { getNativeFile(it)?.getContent() } ?: medicineLogoUrl?.toString()
            }
          }
        }.bind(
          Data::logoInput,
          required = medicineLogoUrl == null,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )
      }
    }
  }
}

fun Container.adminMedicineForm(
  laboratories: Set<Pair<LaboratoryId, LaboratoryName>>,
  atcCodeCodes: Set<Pair<AtcCode.Code, AtcCode.Name>>,
  medicalProfessions: Set<MedicalProfessionModel>,
  medicineLogoUrl: Url? = null
): AdminMedicineForm {
  val adminMedicineForm = AdminMedicineForm(
    laboratories,
    atcCodeCodes,
    medicalProfessions,
    medicineLogoUrl
  )

  this.add(adminMedicineForm)
  return adminMedicineForm
}
