package fr.labodoc

import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

val jsCookie = require("js-cookie")

class Cookie {
  class Options(var expires: Int? = null, var path: String? = null, var domain: String? = null, var secure: Boolean? = null) {
    fun toJs(): dynamic {
      return obj {
        if (expires != null) this.expires = expires
        if (path != null) this.path = path
        if (domain != null) this.domain = domain
        if (secure != null) this.secure = secure
      }
    }
  }

  companion object {
    fun set(name: dynamic, value: dynamic): dynamic {
      return jsCookie.set(name, value)
    }

    fun set(name: dynamic, value: dynamic, options: Options): dynamic {
      return jsCookie.set(name, value, options.toJs())
    }

    fun get(name: dynamic, options: Options): dynamic {
      return jsCookie.get(name, options.toJs())
    }

    fun get(name: dynamic): dynamic {
      return jsCookie.get(name)
    }

    fun get(): dynamic {
      return jsCookie.get()
    }

    fun remove(name: String, options: Options): dynamic {
      return jsCookie.remove(name, options.toJs())
    }

    fun remove(name: String): dynamic {
      return jsCookie.remove(name)
    }

    fun remove(): dynamic {
      return jsCookie.remove()
    }

    // Class functions
    inline fun <reified T : Any> getClass(name: String): T {
      return Json.decodeFromString(
        jsCookie.get(name) as String
      )
    }
    inline fun <reified T : Any> getClass(name: String, options: Options): T {
      return Json.decodeFromString(
        jsCookie.get(name, options.toJs()) as String
      )
    }

    inline fun <reified T : Any> setClass(name: String, value: T): T {
      return Json.decodeFromString(
        jsCookie.set(name, Json.encodeToString(value)) as String
      )
    }

    inline fun <reified T : Any> setClass(name: String, value: T, options: Options): T {
      return Json.decodeFromString(
        jsCookie.set(name, Json.encodeToString(value), options.toJs()) as String
      )
    }

    fun set(cookie: Cookies, value: dynamic): dynamic {
      return jsCookie.set(cookie.cookieName, value)
    }

    fun set(cookie: Cookies, value: dynamic, options: Options): dynamic {
      return jsCookie.set(cookie.cookieName, value, options.toJs())
    }

    fun get(cookie: Cookies, options: Options): dynamic {
      return jsCookie.get(cookie.cookieName, options.toJs())
    }

    fun get(cookie: Cookies): dynamic {
      return jsCookie.get(cookie.cookieName)
    }

    fun remove(cookie: Cookies, options: Options): dynamic {
      return jsCookie.remove(cookie.cookieName, options.toJs())
    }

    fun remove(cookie: Cookies): dynamic {
      return jsCookie.remove(cookie.cookieName)
    }
  }
}

enum class Cookies(val cookieName: String) {
  AuthToken("authenticationToken")
}
