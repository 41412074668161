package fr.labodoc.webapp.pages.healthProfessional.dashboard.components

import fr.labodoc.app.data.healthprofessional.model.*
import fr.labodoc.app.data.healthprofessional.repository.MessagesRepository
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.labodocCheckbox
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.pages.healthProfessional.messages.components.MessageModal
import fr.labodoc.webapp.utils.dateFormat
import fr.labodoc.webapp.utils.ellipsis
import io.kvision.core.onChange
import io.kvision.core.onClick
import io.kvision.form.check.checkBox
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class Messages : SimplePanel(className = "messages") {
  private sealed interface ViewModel {
    sealed class UiState {
      abstract val filters: ObservableState<Filter>

      data class Loading(
        override val filters: ObservableState<Filter>
      ) : UiState()

      data class Error(
        override val filters: ObservableState<Filter>
      ) : UiState()

      data class Loaded(
        override val filters: ObservableState<Filter>,
        val messages: ObservableList<MessageSummaryModel>
      ) : UiState()
    }

    data class Filter(
      val onlyFavorite: Boolean,
      val flashInfo: Boolean,
      val invitation: Boolean
    )

    val uiState: ObservableState<UiState>
    val messageModal: ObservableState<MessageModal?>

    fun filterFavoritesMessages(enabled: Boolean)
    fun filterFlashInfoMessages(enabled: Boolean)
    fun filterInvitationsMessages(enabled: Boolean)
    fun setMessageFavoriteStatus(message: MessageSummaryModel, favoriteStatus: Boolean)
    fun getMessage(message: MessageSummaryModel)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val messageRepository: MessagesRepository by inject()

    private val filters: ObservableValue<ViewModel.Filter> =
      ObservableValue(ViewModel.Filter(onlyFavorite = false, flashInfo = true, invitation = true))

    private val messages: ObservableListWrapper<MessageSummaryModel> =
      ObservableListWrapper()

    override val uiState: ObservableValue<ViewModel.UiState> =
      ObservableValue(ViewModel.UiState.Loading(filters))

    override val messageModal: ObservableValue<MessageModal?> =
      ObservableValue(null)

    init {
      filters.subscribe { filters ->
        refresh(filters)
      }
    }

    private fun refresh(filters: ViewModel.Filter) {
      App.scope.launch {
        messageRepository
          .getMessages(
            onlyFavorite = filters.onlyFavorite,
            flashInfo = filters.flashInfo,
            invitation = filters.invitation
          )
          .fold(
            {
              ViewModel.UiState.Error(this@ViewModelImpl.filters)
            },
            {
              messages.clear()
              messages.addAll(it)

              ViewModel.UiState.Loaded(
                filters = this@ViewModelImpl.filters,
                messages = messages
              )
            }
          )
          .also { uiState.setState(it) }
      }
    }

    private fun updateMessage(messageId: MessageId, block: (MessageSummaryModel) -> MessageSummaryModel) {
      messages.apply {
        find { it.id == messageId }?.let { message ->
          set(indexOf(message), block(message))
        }
      }
    }

    override fun filterFavoritesMessages(enabled: Boolean) {
      filters.setState(filters.getState().copy(onlyFavorite = enabled))
    }

    override fun filterFlashInfoMessages(enabled: Boolean) {
      filters.setState(filters.getState().copy(flashInfo = enabled))
    }

    override fun filterInvitationsMessages(enabled: Boolean) {
      filters.setState(filters.getState().copy(invitation = enabled))
    }

    override fun setMessageFavoriteStatus(message: MessageSummaryModel, favoriteStatus: Boolean) {
      App.scope.launch {
        val response = if (favoriteStatus) {
          messageRepository.favorite(message.id)
        } else {
          messageRepository.unfavorite(message.id)
        }

        response
          .onLeft { Toast.danger("Une erreur est survenue lors de la mise à jour du favori") }
          .onRight { updateMessage(message.id) { it.setFavorite(favoriteStatus) } }
      }
    }

    override fun getMessage(message: MessageSummaryModel) {
      App.scope.launch {
        messageRepository.getMessage(message.id)
          .onRight {
            updateMessage(it.id) { it.setSeen(true) }

            messageModal
              .setState(
                MessageModal(
                  message = it,
                  onFavoriteClicked = { setMessageFavoriteStatus(message, it) }
                )
              )
          }
      }
    }
  }

  private class MessageCard(
    message: MessageSummaryModel,
    onCardClicked: () -> Unit,
    onFavoriteClicked: (Boolean) -> Unit
  ) : SimplePanel(className = "message") {
    init {
      if (!message.seen)
        addCssClass("new")

      div(className = "content") {
        header {
          p(className = "type") {
            rich = true
            content = when (message) {
              is FlashInfoMessageSummaryModel -> """<i class="fa-solid fa-bell"></i> Flash Info"""
              is InvitationMessageSummaryModel -> """<i class="fa-solid fa-envelope"></i> Invitation"""
            }
          }

          p(message.publishedAt.dateFormat, className = "created-date")
        }

        p(message.title.value.ellipsis(70), className = "title")

        val author = when (message) {
          is LabodocMessageSummaryModel -> "LaboDoc"
          is LaboratoryMessageSummaryModel -> message.laboratory.name.value
          is MedicineMessageSummaryModel -> message.laboratory.name.value
          is LearnedSocietyMessageSummaryModel -> message.learnedSociety.acronym?.value ?: message.learnedSociety.name.value
        }
        p(author, className = "author")

        onClick {
          onCardClicked()
        }
      }

      checkBox {
        addCssClass("favorite")
        value = message.favorite

        onChange {
          onFavoriteClicked(value)
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      header {
        if (uiState is ViewModel.UiState.Loaded) {
          div(className = "welcome-message").bind(uiState.messages) { messages ->
            val unreadMessagesCount = messages.count { !it.seen }

            icon("fa-solid fa-envelope") {
              if (unreadMessagesCount > 0)
                span(unreadMessagesCount.toString(), className = "message-number")
            }
            p {
              val userFirstName = when (val user = App.user.getState()) {
                is HealthProfessionalUserModel -> user.firstName
                else -> null
              }

              val messageCountSentence = if (unreadMessagesCount > 0)
                "Vous avez $unreadMessagesCount nouveaux messages"
              else
                "Vous avez 0 nouveau message"

              rich = true
              content =
                """<span class="hello">Bonjour ${userFirstName?.value}</span>,<br>${messageCountSentence}"""
            }
          }
        } else {
          div(className = "welcome-message") {
            icon("fa-solid fa-envelope")
            p {
              val userFirstName = when (val user = App.user.getState()) {
                is HealthProfessionalUserModel -> user.firstName
                else -> null
              }

              rich = true
              content =
                """<span class="hello">Bonjour ${userFirstName?.value}</span>,<br>Vous avez 0 nouveau message"""
            }
          }
        }

        div(className = "filters").bind(uiState.filters) { filters ->
          labodocCheckbox {
            label = "Favoris"
            value = filters.onlyFavorite

            onChange { viewModel.filterFavoritesMessages(value) }
          }
          labodocCheckbox {
            label = "Flash info"
            value = filters.flashInfo

            onChange { viewModel.filterFlashInfoMessages(value) }
          }
          labodocCheckbox {
            label = "Invitations"
            value = filters.invitation

            onChange { viewModel.filterInvitationsMessages(value) }
          }
        }
      }

      div(className = "container") {
        when (uiState) {
          is ViewModel.UiState.Loading -> labodocSpinner()

          is ViewModel.UiState.Error -> {
            div(className = "no-messages") {
              p("Impossible de charger les messages", className = "text")
            }
          }

          is ViewModel.UiState.Loaded -> {
            if (uiState.messages.isEmpty()) {
              val filters = uiState.filters.getState()

              div(className = "no-messages") {
                if (!filters.flashInfo && !filters.invitation)
                  p("Séléctionnez au moins un filtre Flash info ou Invitation", className = "text")
                else if (filters.onlyFavorite)
                  p("Aucun message en favoris", className = "text")
                else {
                  p("Actuellement, vous n'avez pas de messages", className = "text")
                  p("Vous serez alerté en cas de nouveaux messages", className = "tip")
                }
              }
            } else {
              div(className = "list").bindEach(uiState.messages) { message ->
                add(
                  MessageCard(
                    message = message,
                    onCardClicked = { viewModel.getMessage(message) },
                    onFavoriteClicked = { viewModel.setMessageFavoriteStatus(message, it) }
                  )
                )
              }
            }
          }
        }
      }
    }

    viewModel.messageModal.subscribe {
      it?.show()
    }
  }
}
