package fr.labodoc.webapp.di

import fr.labodoc.Cookie
import fr.labodoc.Cookies
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.client.httpClient
import fr.labodoc.process
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.resources.*
import io.ktor.serialization.kotlinx.json.*
import org.koin.dsl.module

val dataSourceModule = module {
  single<HttpClient> {
    httpClient {
      defaultRequest {
        url(process.env.API_URL)
      }

      install(Auth) {
        bearer {
          loadTokens {
            (Cookie.get(Cookies.AuthToken) as? String)?.let {
              BearerTokens(it, it)
            }
          }

          refreshTokens {
            (Cookie.get(Cookies.AuthToken) as? String)?.let {
              BearerTokens(it, it)
            }
          }
        }
      }

      install(Resources)

      install(ContentNegotiation) {
        json()
      }
    }
  }

  single<AdminApiClient> {
    AdminApiClient(get())
  }

  single<HealthProfessionalApiClient> {
    HealthProfessionalApiClient(get())
  }
}
