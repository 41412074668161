package fr.labodoc.webapp.pages.healthProfessional.dashboard.components

import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.app.data.healthprofessional.model.AtcCategoryModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.model.MedicineSummaryModel
import fr.labodoc.app.data.healthprofessional.repository.AtcCodesRepository
import fr.labodoc.app.data.healthprofessional.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.medicine.AtcCode
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocSelectCheckbox
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.utils.containsNormalized
import io.kvision.core.Display
import io.kvision.form.check.radio
import io.kvision.form.text.text
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

var medicalItemsFilterListCategory: MedicalItems.ViewModel.MedicineFilter.ListCategory = MedicalItems.ViewModel.MedicineFilter.ListCategory.Segmentation

class MedicalItems : SimplePanel(className = "medical-items") {
  interface ViewModel {
    sealed class UiState {
      data object Loading: UiState()

      data object Error: UiState()

      data class Loaded(
        val medicines: ObservableList<MedicineSummaryModel>,
        val atcCodes: ObservableList<AtcCategoryModel>
      ): UiState()
    }

    data class MedicineFilter(
      val listCategory: ListCategory,
      val name: String?,
      val atcCodeCodes: Set<AtcCode.Code>
    ) {
      enum class ListCategory {
        All,
        Segmentation
      }
    }

    sealed interface MedicineSort {
      data object Segmentation : MedicineSort
      data object Alphabetical : MedicineSort
    }

    val filter: ObservableState<MedicineFilter>

    val sort: ObservableState<MedicineSort>

    val uiState: ObservableState<UiState>

    fun filterMedicines(filter: MedicineFilter)

    fun sortMedicines(sort: MedicineSort)
  }

  class ViewModelImpl : ViewModel, KoinComponent {
    private val medicineRepository: MedicinesRepository by inject()
    private val atcRepository: AtcCodesRepository by inject()

    private val medicines: ObservableListWrapper<MedicineSummaryModel> =
      ObservableListWrapper()

    private val filteredMedicines: ObservableListWrapper<MedicineSummaryModel> =
      ObservableListWrapper()

    private val atcCodes: ObservableListWrapper<AtcCategoryModel> =
      ObservableListWrapper()

    override val filter: ObservableValue<ViewModel.MedicineFilter> =
      ObservableValue(
        ViewModel.MedicineFilter(
          medicalItemsFilterListCategory,
          null,
          setOf()
        )
      )

    override val sort: ObservableValue<ViewModel.MedicineSort> =
      ObservableValue(ViewModel.MedicineSort.Segmentation)

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        val newUiState = either {
          parZip(
            {
              when (filter.value.listCategory) {
                ViewModel.MedicineFilter.ListCategory.All -> medicineRepository.getMedicines()
                ViewModel.MedicineFilter.ListCategory.Segmentation -> medicineRepository.getMedicinesForSpeciality()
              }.bind()
            },
            {
              atcRepository
                .getAtcCategoriesWithCodesInUsage()
                .bind()
            }
          ) { medicines, atcCodes ->
            this@ViewModelImpl.medicines.addAll(medicines)
            this@ViewModelImpl.atcCodes.addAll(atcCodes)

            ViewModel.UiState.Loaded(
              medicines = filteredMedicines,
              atcCodes = this@ViewModelImpl.atcCodes
            )
          }
        }.fold(
          {
            ViewModel.UiState.Error
          },
          {
            it
          }
        )

        observableValue.setState(newUiState)
      }

      observableValue
    }

    init {
      medicines.subscribe { medicines ->
        filterMedicines(medicines, filter.value)
      }

      sort.subscribe { sort ->
        sortMedicines(medicines, sort)
      }

      filter.subscribe { filter ->
        filterMedicines(medicines, filter)
      }
    }

    private fun filterMedicines(medicines: List<MedicineSummaryModel>, filter: ViewModel.MedicineFilter) {
      medicalItemsFilterListCategory = filter.listCategory

      val filteredMedicines = medicines.filter { medicine ->
        val nameMatches = if (filter.name != null) medicine.name.value.containsNormalized(filter.name, true) else true
        val atcCodeMatches = if (filter.atcCodeCodes.isNotEmpty()) medicine.atcCode in filter.atcCodeCodes else true

        nameMatches && atcCodeMatches
      }

      this.filteredMedicines.clear()
      this.filteredMedicines.addAll(filteredMedicines)
    }

    private fun sortMedicines(medicines: List<MedicineSummaryModel>, sort: ViewModel.MedicineSort) {
      val sortedMedicines = when (sort) {
        ViewModel.MedicineSort.Alphabetical -> medicines.sortedBy { it.name.value }
        ViewModel.MedicineSort.Segmentation -> medicines.sortedBy { it.id.value }
      }

      this.medicines.clear()
      this.medicines.addAll(sortedMedicines)
    }

    override fun filterMedicines(filter: ViewModel.MedicineFilter) {
      medicalItemsFilterListCategory = filter.listCategory

      if (this.filter.value.listCategory != filter.listCategory) {
        uiState.setState(ViewModel.UiState.Loading)

        App.scope.launch {
          val newUiState = when (filter.listCategory) {
            ViewModel.MedicineFilter.ListCategory.All -> medicineRepository.getMedicines()
            ViewModel.MedicineFilter.ListCategory.Segmentation -> medicineRepository.getMedicinesForSpeciality()
          }.fold(
            {
              ViewModel.UiState.Error
            },
            { medicines ->
              this@ViewModelImpl.medicines.clear()
              this@ViewModelImpl.medicines.addAll(medicines)

              ViewModel.UiState.Loaded(
                medicines = filteredMedicines,
                atcCodes = this@ViewModelImpl.atcCodes
              )
            }
          )

          uiState.setState(newUiState)
        }
      }

      this.filter.setState(filter)
    }

    override fun sortMedicines(sort: ViewModel.MedicineSort) {
      this.sort.setState(sort)
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  private val filterOptionsOpen = ObservableValue(false)
  private val sortOptionsOpen = ObservableValue(false)

  init {
    val user = App.user.getState()

    div(className = "page-width") {
      header {
        if (user != null && user is HealthProfessionalUserModel) {
          div(className = "speciality") {
            h2 {
              content = "Spécialité ${user.medicalSpeciality.name.value}"
            }
          }
        }

        div(className = "filters") {
          div(className = "a") {
            div(className = "b") {
              button("Médicaments", className = "active")
              text {
                icon("fa-solid fa-search")
                subscribe {
                  viewModel.filterMedicines(
                    viewModel.filter.getState().copy(name = it)
                  )
                }
              }.bind(viewModel.filter) { filter ->
                value = filter.name
              }
            }
            div(className = "c") {
              button("Filtres") {
                onClick { filterOptionsOpen.setState(!filterOptionsOpen.getState()) }

                filterOptionsOpen.subscribe {
                  if (it) addCssClass("open") else removeCssClass("open")
                }
              }
              button("Trier par") {
                onClick { sortOptionsOpen.setState(!sortOptionsOpen.getState()) }

                sortOptionsOpen.subscribe {
                  if (it) addCssClass("open") else removeCssClass("open")
                }
              }
            }
          }

          div(className = "filter-segmentation") {
            radio(
              name = "filter",
              label = "Médicaments de ma spécialité"
            ) {
              subscribe {
                if (it)
                  viewModel.filterMedicines(
                    viewModel.filter.getState().copy(listCategory = ViewModel.MedicineFilter.ListCategory.Segmentation)
                  )
              }
            }.bind(viewModel.filter) { filter ->
              value = filter.listCategory == ViewModel.MedicineFilter.ListCategory.Segmentation
            }

            radio(
              name = "filter",
              label = "Médicaments toutes spécialités"
            ) {
              subscribe {
                if (it)
                  viewModel.filterMedicines(
                    viewModel.filter.getState().copy(listCategory = ViewModel.MedicineFilter.ListCategory.All)
                  )
              }
            }.bind(viewModel.filter) { filter ->
              value = filter.listCategory == ViewModel.MedicineFilter.ListCategory.All
            }
          }

          div(className = "filter-options") {
            labodocSelectCheckbox(label = "Code ATC") {
              addCssClass("atc")

              subscribe {
                val atcCodeCodes = values?.mapNotNull { AtcCode.Code(it).getOrNull() }.orEmpty().toSet()

                viewModel.filterMedicines(
                  viewModel.filter.getState().copy(atcCodeCodes = atcCodeCodes)
                )
              }
            }.bind(viewModel.uiState, removeChildren = false) { uiState ->
              options = when (uiState) {
                is ViewModel.UiState.Loaded -> {
                  uiState.atcCodes.associate {
                    it.name.value to it.codes.map { it.code.value to it.name.value }
                  }
                }

                else -> {
                  null
                }
              }
            }.bind(viewModel.filter, removeChildren = false) { filter ->
              values = filter.atcCodeCodes.map { it.value }
            }

            filterOptionsOpen.subscribe {
              this.display = if (it) Display.FLEX else Display.NONE
            }
          }

          div(className = "sort-options") {
            radio(
              name = "sort",
              label = "Défaut"
            ) {
              subscribe {
                if (it)
                  viewModel.sortMedicines(ViewModel.MedicineSort.Segmentation)
              }
            }.bind(viewModel.sort) { sort ->
              value = sort is ViewModel.MedicineSort.Segmentation
            }

            radio(
              name = "sort",
              label = "Ordre alphabétique"
            ) {
              subscribe {
                if (it)
                  viewModel.sortMedicines(ViewModel.MedicineSort.Alphabetical)
              }
            }.bind(viewModel.sort) { sort ->
              value = sort is ViewModel.MedicineSort.Alphabetical
            }

            sortOptionsOpen.subscribe {
              this.display = if (it) Display.FLEX else Display.NONE
            }
          }
        }
      }

      div(className = "container").bind(viewModel.uiState) { uiState ->
        when (uiState) {
          is ViewModel.UiState.Loading -> {
            labodocSpinner()
          }

          is ViewModel.UiState.Error -> {
            p("Une erreur est survenue")
          }

          is ViewModel.UiState.Loaded -> {
            if (uiState.medicines.isEmpty())
              p("Aucun médicaments", className = "no-medicines")
            else
              div(className = "cards").bindEach(uiState.medicines) { medicine ->
                navigoLink("", Page.HealthProfessionalMedicineSheet(medicine.id)) {
                  div(className = "medicine-card") {
                    if (medicine.favorite && medicine.unreadDocuments > 0)
                      addCssClass("new-documents")

                    image(medicine.logoUrl.toString())

                    hr()

                    p(className = "name") {
                      content = medicine.name.value
                    }

                    p(className = "main-composition") {
                      content = medicine.mainComposition.value
                    }

                    p(className = "laboratory") {
                      content = medicine.laboratory.name.value
                    }
                  }
                }
              }
          }
        }
      }
    }
  }
}
