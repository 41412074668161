package fr.labodoc.webapp.pages.register.sections

import arrow.core.nel
import arrow.core.raise.catch
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import arrow.fx.coroutines.parZipOrAccumulate
import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.healthprofessional.model.DepartmentModel
import fr.labodoc.app.data.healthprofessional.model.MedicalProfessionModel
import fr.labodoc.app.data.healthprofessional.repository.DepartmentsRepository
import fr.labodoc.app.data.healthprofessional.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.register.RegisterPage
import fr.labodoc.webapp.utils.containsNormalized
import fr.labodoc.webapp.utils.toInputFile
import io.kvision.core.onClickLaunch
import io.kvision.form.check.radio
import io.kvision.form.formPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.html.*
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import io.kvision.types.KFile
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class WithoutCardForm(
  registerPageState: MutableState<RegisterPage.State>
) : SimplePanel(className = "without-card-form"){
  private interface ViewModel {
    sealed class UIState {
      data class PersonalInformationForm(
        val data: PersonalInformation?
      ) : UIState()

      data class HealthProfessionalInformationForm(
        val data: ProfessionalInformation?,
        val departments: Set<DepartmentModel>,
        val medicalProfessions: Set<MedicalProfessionModel>
      ) : UIState()

      data class HealthProfessionalAlreadyRegistered(
        val rppsNumber: RPPSNumber
      ): UIState()

      data class HealthProfessionalNotFound(
        val rppsNumber: RPPSNumber
      ): UIState()

      data class NotificationsSettingsForm(
        val data: NotificationsSettings?
      ): UIState()

      data class AccountInformationForm(
        val data: AccountInformation?
      ): UIState()

      data class EmailAddressAlreadyUsed(
        val emailAddress: EmailAddress
      ): UIState()

      data class ComplementaryInformationForm(
        val data: ComplementaryInformation?
      ): UIState()
    }

    data class PersonalInformation(
      val firstName: FirstName,
      val lastName: LastName,
      val phoneNumber: PhoneNumber
    )

    data class ProfessionalInformation(
      val medicalProfession: MedicalProfessionId,
      val medicalSpeciality: MedicalSpecialityId,
      val facilityName: String,
      val facilityUnit: String,
      val facilityDepartmentCode: DepartmentCode,
      val facilityCity: String,
      val rppsNumber: RPPSNumber?,
      val supportingDocument: InputFile
    )

    data class NotificationsSettings(
      val daily: Boolean,
      val weekly: Boolean
    )

    data class AccountInformation(
      val emailAddress: EmailAddress,
      val password: Password,
    )

    data class ComplementaryInformation(
      val partnerCode: PartnerCode?,
      val comment: String?
    )

    class RegistrationForm {
      @Serializable
      data class PersonalInformation(
        val firstName: String? = null,
        val lastName: String? = null,
        val phoneNumber: String? = null
      ) {
        companion object {
          fun validateFirstName(firstName: String?): String? = FirstName(firstName ?: "").fold(
            { error: Errors.FirstName.Invalid ->
              when (error) {
                Errors.FirstName.Invalid.Blank -> "Ne peut pas être vide"
                Errors.FirstName.Invalid.TooLong -> "Trop long"
              }
            },
            {
              null
            }
          )

          fun validateLastName(lastName: String?): String? = FirstName(lastName ?: "").fold(
            { error: Errors.FirstName.Invalid ->
              when (error) {
                Errors.FirstName.Invalid.Blank -> "Ne peut pas être vide"
                Errors.FirstName.Invalid.TooLong -> "Trop long"
              }
            },
            {
              null
            }
          )

          fun validatePhoneNumber(phoneNumber: String?): String? {
            return when {
                phoneNumber.isNullOrBlank() -> "Ne peut pas être vide"
                else -> null
            }
          }
        }
      }

      @Serializable
      data class ProfessionalInformation(
        val medicalProfessionId: String? = null,
        val medicalSpecialityId: String? = null,
        val facilityName: String? = null,
        val facilityUnit: String? = null,
        val facilityDepartmentCode: String? = null,
        val facilityCity: String?  = null,
        val rppsNumber: String? = null,
        val supportingDocument: List<KFile> ? = null
      ) {
        companion object {
          fun validateFacilityName(facilityName: String?): String? {
            return when {
                facilityName.isNullOrBlank() -> "Ne peut pas être vide"
                facilityName.length > 50 -> "Trop long"
                else -> null
            }
          }

          fun validateFacilityUnit(facilityUnit: String?): String? {
            return when {
                facilityUnit.isNullOrBlank() -> "Ne peut pas être vide"
                facilityUnit.length > 50 -> "Trop long"
                else -> null
            }
          }

          fun validateFacilityCity(facilityCity: String?): String? {
            return when {
                facilityCity.isNullOrBlank() -> "Ne peut pas être vide"
                facilityCity.length > 50 -> "Trop long"
                else -> null
            }
          }

          fun validateRPPSNumber(rppsNumber: String?): String? = rppsNumber?.let {
            RPPSNumber(it).fold(
              { error: Errors.RPPSNumber.Invalid ->
                when (error) {
                  Errors.RPPSNumber.Invalid.Format -> "Le format est invalide"
                }
              },
              {
                null
              }
            )
          }
        }
      }

      @Serializable
      data class NotificationsSettings(
        val daily: Boolean = false,
        val weekly: Boolean = false
      )

      @Serializable
      data class AccountInformation(
        val emailAddress: String? = null,
        val password: String? = null,
        val passwordConfirmation: String? = null
      ) {
        companion object {
          fun validateEmailAddress(emailAddress: String?): String? = EmailAddress(emailAddress ?: "").fold(
            { error: Errors.EmailAddress.Invalid ->
              when (error) {
                Errors.EmailAddress.Invalid.Format -> "Le format est invalide"
                Errors.EmailAddress.Invalid.TooLong -> "Trop long"
              }
            },
            {
              null
            }
          )

          fun validatePassword(password: String?): String? = Password(password ?: "").fold(
            { error: Errors.Password.Invalid ->
              when (error) {
                Errors.Password.Invalid.TooShort -> "Trop court"
                Errors.Password.Invalid.TooLong -> "Trop long"
                Errors.Password.Invalid.NoLowercaseLetter -> "Pas de minuscule"
                Errors.Password.Invalid.NoUppercaseLetter -> "Pas de majuscule"
                Errors.Password.Invalid.NoDigit -> "Pas de chiffre"
                Errors.Password.Invalid.NoSpecialCharacter -> "Pas de caractère special"
              }
            },
            {
              null
            }
          )

          fun validateSamePassword(password: String?, passwordConfirmation: String?): String? {
            return if (password != passwordConfirmation)
              "Les mots de passe ne sont pas identiques"
            else
              null
          }
        }
      }

      @Serializable
      data class ComplementaryInformation(
        val partnerCode: String? = null,
        val comment: String? = null
      ) {
        companion object {
          fun validatePartnerCode(partnerCode: String?): String? =
            partnerCode?.let {
              PartnerCode(partnerCode).fold(
                { error: Errors.Partner.Code.Invalid ->
                  when (error) {
                    Errors.Partner.Code.Invalid.Blank -> "Ne peut pas être vide si renseigné"
                    Errors.Partner.Code.Invalid.TooLong -> "Trop long"
                  }
                },
                {
                  null
                }
              )
            }
        }
      }
    }

    val uiState: ObservableState<UIState>

    fun goBack()

    fun submitPersonalInformationForm(personalInformation: RegistrationForm.PersonalInformation)

    fun submitProfessionalInformation(professionalInformation: RegistrationForm.ProfessionalInformation)

    fun submitNotificationSettings(notificationsSettings: RegistrationForm.NotificationsSettings)

    fun submitAccountInformation(accountInformation: RegistrationForm.AccountInformation)

    fun submitComplementaryInformation(complementaryInformation: RegistrationForm.ComplementaryInformation)
  }

  private class ViewModelImpl(
    private val registerPageState: MutableState<RegisterPage.State>
  ): ViewModel, KoinComponent {
    private val userRepository: UsersRepository by inject()
    private val medicalProfessionRepository: MedicalProfessionsRepository by inject()
    private val departmentRepository: DepartmentsRepository by inject()

    override val uiState: ObservableValue<ViewModel.UIState> =
      ObservableValue(ViewModel.UIState.PersonalInformationForm(null))

    private var personalInformation: ViewModel.PersonalInformation? = null
    private var professionalInformation: ViewModel.ProfessionalInformation? = null
    private var notificationsSettings: ViewModel.NotificationsSettings? = null
    private var accountInformation: ViewModel.AccountInformation? = null
    private var complementaryInformation: ViewModel.ComplementaryInformation? = null

    private val medicalProfessions: MutableSet<MedicalProfessionModel> = mutableSetOf()
    private val departments: MutableSet<DepartmentModel> = mutableSetOf()

    init {
      App.scope.launch {
        medicalProfessionRepository
          .getMedicalProfessions()
          .onRight { medicalProfessions.addAll(it.filter { it.name.value.lowercase().containsNormalized("médecin") }) }

        departmentRepository
          .getDepartments()
          .onRight { departments.addAll(it) }
      }
    }

    override fun goBack() {
      when (uiState.value) {
        is ViewModel.UIState.PersonalInformationForm -> {
          registerPageState.setState(RegisterPage.State.Home)
        }

        is ViewModel.UIState.HealthProfessionalInformationForm -> {
          val newUiState = ViewModel.UIState.PersonalInformationForm(
            data = personalInformation
          )

          uiState.setState(newUiState)
        }

        is ViewModel.UIState.NotificationsSettingsForm,
        is ViewModel.UIState.HealthProfessionalAlreadyRegistered,
        is ViewModel.UIState.HealthProfessionalNotFound -> {
          val newUiState = ViewModel.UIState.HealthProfessionalInformationForm(
            data = professionalInformation,
            departments = departments,
            medicalProfessions = medicalProfessions
          )

          uiState.setState(newUiState)
        }

        is ViewModel.UIState.AccountInformationForm -> {
          val newUiState = ViewModel.UIState.NotificationsSettingsForm(
            data = notificationsSettings
          )

          uiState.setState(newUiState)
        }

        is ViewModel.UIState.ComplementaryInformationForm,
        is ViewModel.UIState.EmailAddressAlreadyUsed -> {
          val newUiState = ViewModel.UIState.AccountInformationForm(
            accountInformation
          )

          uiState.setState(newUiState)
        }
      }
    }

    override fun submitPersonalInformationForm(personalInformation: ViewModel.RegistrationForm.PersonalInformation) {
      App.scope.launch {
        either<List<String>, ViewModel.PersonalInformation> {
          zipOrAccumulate(
            {
              ensureNotNull(personalInformation.firstName) { "Le champ prénom est obligatoire" }

              FirstName(personalInformation.firstName)
                .mapLeft { error: Errors.FirstName.Invalid ->
                  when (error) {
                    Errors.FirstName.Invalid.Blank -> "Le champ prénom ne peut pas être vide"
                    Errors.FirstName.Invalid.TooLong -> "Le champ prénom est trop long"
                  }
                }
                .bind()
            },
            {
              ensureNotNull(personalInformation.lastName) { "Le champ prénom est obligatoire" }

              LastName(personalInformation.lastName)
                .mapLeft { error: Errors.LastName.Invalid ->
                  when (error) {
                    Errors.LastName.Invalid.Blank -> "Le champ nom de famille ne peut pas être vide"
                    Errors.LastName.Invalid.TooLong -> "Le champ nom de famille est trop long"
                  }
                }
                .bind()
            },
            {
              ensureNotNull(personalInformation.phoneNumber) { "Le champ numéro de téléphone est obligatoire" }

              PhoneNumber(personalInformation.phoneNumber)
            }
          ) { firstName, lastName, phoneNumber ->
            ViewModel.PersonalInformation(
              firstName = firstName,
              lastName = lastName,
              phoneNumber = phoneNumber
            )
          }
        }.onLeft { errors: List<String> ->
          errors.forEach { error: String ->
            Toast.danger(error)
          }
        }.onRight { personalInformation: ViewModel.PersonalInformation ->
          this@ViewModelImpl.personalInformation = personalInformation

          uiState.setState(
            ViewModel.UIState.HealthProfessionalInformationForm(
              data = professionalInformation,
              departments = departments,
              medicalProfessions = medicalProfessions
            )
          )
        }
      }
    }

    override fun submitProfessionalInformation(professionalInformation: ViewModel.RegistrationForm.ProfessionalInformation) {
      App.scope.launch {
        either<List<String>, ViewModel.ProfessionalInformation> {
          zipOrAccumulate(
            {
              ensureNotNull(professionalInformation.medicalProfessionId) { "Le champ profession est obligatoire" }

              catch({
                MedicalProfessionId(uuidFrom(professionalInformation.medicalProfessionId))
              }) {
                raise("Le champ profession est invalide")
              }
            },
            {
              ensureNotNull(professionalInformation.medicalSpecialityId) { "Le champ spécialité est obligatoire" }

              catch({
                MedicalSpecialityId(uuidFrom(professionalInformation.medicalSpecialityId))
              }) {
                raise("Le champ spécialité est invalide")
              }
            },
            {
              ensureNotNull(professionalInformation.facilityName) { "Le champ lieu principal d'exercice est obligatoire" }
            },
            {
              ensureNotNull(professionalInformation.facilityUnit) { "Le champ service est obligatoire" }
            },
            {
              ensureNotNull(professionalInformation.facilityDepartmentCode) { "Le champ départment est obligatoire" }

              DepartmentCode(professionalInformation.facilityDepartmentCode)
                .mapLeft { error: Errors.Department.Code.Invalid ->
                  when (error) {
                    Errors.Department.Code.Invalid.Format -> "Le champ départment est invalide"
                  }
                }
                .bind()
            },
            {
              ensureNotNull(professionalInformation.facilityUnit) { "Le champ ville est obligatoire" }
            },
            {
              professionalInformation.rppsNumber?.let { rppsNumber: String ->
                RPPSNumber(rppsNumber)
                  .mapLeft { error: Errors.RPPSNumber.Invalid ->
                    when (error) {
                      Errors.RPPSNumber.Invalid.Format -> "Le champs numéro RPPS est invalide"
                    }
                  }
                  .bind()
              }
            },
            {
              val supportingDocument = ensureNotNull(professionalInformation.supportingDocument?.firstOrNull()) { "Le champ justificatif est obligatoire" }

              catch({
                supportingDocument.toInputFile()
              }) {
                raise("Le champ justificatif est invalide")
              }
            }
          ) { medicalProfession, medicalSpeciality, facilityName, facilityUnit, facilityDepartmentCode, facilityCity, rppsNumber, supportingDocument ->
            ViewModel.ProfessionalInformation(
              medicalProfession = medicalProfession,
              medicalSpeciality = medicalSpeciality,
              facilityName = facilityName,
              facilityUnit = facilityUnit,
              facilityDepartmentCode = facilityDepartmentCode,
              facilityCity = facilityCity,
              rppsNumber = rppsNumber,
              supportingDocument = supportingDocument
            )
          }
        }.onLeft { errors: List<String> ->
          errors.forEach { error: String ->
            Toast.danger(error)
          }
        }.onRight { professionalInformation: ViewModel.ProfessionalInformation ->
          this@ViewModelImpl.professionalInformation = professionalInformation

          uiState.setState(
            ViewModel.UIState.NotificationsSettingsForm(
              data = notificationsSettings
            )
          )
        }
      }
    }

    override fun submitNotificationSettings(notificationsSettings: ViewModel.RegistrationForm.NotificationsSettings) {
      App.scope.launch {
        this@ViewModelImpl.notificationsSettings = ViewModel.NotificationsSettings(
          daily = notificationsSettings.daily,
          weekly = notificationsSettings.weekly
        )

        uiState.setState(
          ViewModel.UIState.AccountInformationForm(
            data = accountInformation
          )
        )
      }
    }

    override fun submitAccountInformation(accountInformation: ViewModel.RegistrationForm.AccountInformation) {
      App.scope.launch {
        either<List<String>, ViewModel.AccountInformation> {
          parZipOrAccumulate(
            {
              ensureNotNull(accountInformation.emailAddress) { "" }

              EmailAddress(accountInformation.emailAddress)
                .mapLeft {
                  "asd"
                }
                .bind()
            },
            {
              ensureNotNull(accountInformation.password) { "" }

              Password(accountInformation.password)
                .mapLeft {
                  "asd"
                }
                .bind()
            }
          ) { emailAddress, password ->
            ViewModel.AccountInformation(
              emailAddress = emailAddress,
              password = password
            )
          }
        }.onLeft { errors: List<String> ->
          errors.forEach { error: String ->
            Toast.danger(error)
          }
        }.onRight { accountInformation: ViewModel.AccountInformation ->
          this@ViewModelImpl.accountInformation = accountInformation

          uiState.setState(
            ViewModel.UIState.ComplementaryInformationForm(
              data = complementaryInformation
            )
          )
        }
      }
    }

    override fun submitComplementaryInformation(complementaryInformation: ViewModel.RegistrationForm.ComplementaryInformation) {
      App.scope.launch {
        either<List<String>, ViewModel.ComplementaryInformation> {
          ViewModel.ComplementaryInformation(
            partnerCode = complementaryInformation.partnerCode?.let { partnerCode ->
              PartnerCode(partnerCode)
                .mapLeft { error ->
                  when (error) {
                    Errors.Partner.Code.Invalid.Blank -> "Le code partenaire ne peut pas être vide si renseigné"
                    Errors.Partner.Code.Invalid.TooLong -> "Le code partenaire est trop long"
                  }.nel()
                }
                .bind()
            },
            comment = complementaryInformation.comment
          )
        }.onLeft { errors: List<String> ->
          errors.forEach { error: String ->
            Toast.danger(error)
          }
        }.onRight { complementaryInformation: ViewModel.ComplementaryInformation ->
          this@ViewModelImpl.complementaryInformation = complementaryInformation

          sendRegistration()
        }
      }
    }

    private suspend fun sendRegistration() {
      either<List<String>, Unit> {
        zipOrAccumulate(
          {
            ensureNotNull(personalInformation) { "Les informations personnelles ne sont pas renseignées" }
          },
          {
            ensureNotNull(professionalInformation) { "Les informations professionnelles ne sont pas renseignées" }
          },
          {
            ensureNotNull(notificationsSettings) { "Les préférences de notification ne sont pas renseignées" }
          },
          {
            ensureNotNull(accountInformation) { "Les informations de compte ne sont pas renseignées" }
          },
          {
            ensureNotNull(complementaryInformation) { "Les informations complémentaires ne sont pas renseignées" }
          }
        ) { personalInformation, professionalInformation, notificationSettings, accountInformation, complementaryInformation ->
          userRepository
            .registerWithoutMedicalCard(
              rppsNumber = professionalInformation.rppsNumber,
              firstName = personalInformation.firstName,
              lastName = personalInformation.lastName,
              phoneNumber = personalInformation.phoneNumber,
              medicalSpeciality = professionalInformation.medicalSpeciality,
              facilityName = professionalInformation.facilityName,
              facilityUnit = professionalInformation.facilityUnit,
              facilityCity = professionalInformation.facilityCity,
              facilityDepartment = professionalInformation.facilityDepartmentCode,
              supportingDocument = professionalInformation.supportingDocument,
              dailyNotificationEnabled = notificationSettings.daily,
              weeklyNotificationEnabled = notificationSettings.weekly,
              emailAddress = accountInformation.emailAddress,
              password = accountInformation.password,
              comment = complementaryInformation.comment,
              partnerCode = complementaryInformation.partnerCode
            )
            .onLeft { error: ErrorResponse ->
              when (error.code) {
                Errors.RPPSNumber.NotFound().code -> {
                  if (professionalInformation.rppsNumber != null) {
                    val newUiState = ViewModel.UIState.HealthProfessionalNotFound(
                      rppsNumber = professionalInformation.rppsNumber
                    )

                    uiState.setState(newUiState)
                  } else {
                    Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                  }
                }

                Errors.RPPSNumber.AlreadyUsed.code -> {
                  if (professionalInformation.rppsNumber != null) {
                    val newUiState = ViewModel.UIState.HealthProfessionalAlreadyRegistered(
                      rppsNumber = professionalInformation.rppsNumber
                    )

                    uiState.setState(newUiState)
                  } else {
                    Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                  }
                }

                Errors.User.EmailAlreadyUsed.code -> {
                  val newUiState = ViewModel.UIState.EmailAddressAlreadyUsed(
                    emailAddress = accountInformation.emailAddress
                  )

                  uiState.setState(newUiState)
                }

                Errors.Partner.NotFound().code -> {
                  Toast.danger("Le code partenaire [${complementaryInformation.partnerCode?.value}] n'existe pas")
                }

                else -> {
                  Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                }
              }
            }
            .onRight { _: Unit ->
              App.routing.navigate(Page.Home(Page.Home.Popup.ApplicationSuccessful(true)))
            }
        }
      }.onLeft { errors: List<String> ->
        errors.forEach { error: String ->
          Toast.danger(error)
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(registerPageState)

  private val stepper = LabodocStepper().apply {
    addStep("Informations personnelles")
    addStep("Informations professionnelles")
    addStep("Préférences de notification")
    addStep("Création du compte")
    addStep("Informations complémentaires")

    currentStep.setState(1)
  }

  init {
    require("./css/pages/register/sections/without-card-form.css")

    header {
      add(stepper)
    }

    div(className = "page-width") {
      div(className = "container").bind(viewModel.uiState) { uiState ->
        when (uiState) {
          is ViewModel.UIState.PersonalInformationForm -> {
            stepper.currentStep.setState(1)

            div(className = "personal-information-form") {
              p {
                strong("Cette méthode d'inscription concerne uniquement :")
                br()
                content =
                  "Les médecins qui ne sont pas inscrits au CNOM et qui exercent sur le territoire français."
              }

              val form = formPanel<ViewModel.RegistrationForm.PersonalInformation> {
                labodocText {
                  label = "Prénom"
                  value = uiState.data?.firstName?.value
                }.bind(
                  ViewModel.RegistrationForm.PersonalInformation::firstName,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.PersonalInformation.validateFirstName(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.PersonalInformation.validateFirstName(it.value) }
                )

                labodocText {
                  label = "Nom de famille"
                  value = uiState.data?.lastName?.value
                }.bind(
                  ViewModel.RegistrationForm.PersonalInformation::lastName,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.PersonalInformation.validateLastName(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.PersonalInformation.validateLastName(it.value) }
                )

                labodocText {
                  label = "Téléphone portable"
                  notice = "Nous sommes susceptibles de vous appeler pour confirmer votre inscription."
                  type = InputType.TEL
                  value = uiState.data?.phoneNumber?.value
                }.bind(
                  ViewModel.RegistrationForm.PersonalInformation::phoneNumber,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.PersonalInformation.validatePhoneNumber(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.PersonalInformation.validatePhoneNumber(it.value) }
                )
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitPersonalInformationForm(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalInformationForm -> {
            stepper.currentStep.setState(2)

            div(className = "health-professional-information-form") {
              val form = formPanel<ViewModel.RegistrationForm.ProfessionalInformation> {
                val medicalProfessionField = labodocSelect {
                  label = "Profession"
                  options = uiState.medicalProfessions
                    .map { it.id.value.toString() to it.name.value }
                  value = uiState.data?.medicalProfession?.value?.toString()
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::medicalProfessionId,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )

                labodocSelect {
                  label = "Spécialité"
                  value = uiState.data?.medicalSpeciality?.value?.toString()

                  medicalProfessionField.subscribe { medicalProfessionId: String? ->
                    val medicalSpecialitiesOptions = uiState.medicalProfessions
                      .find { it.id.value.toString() == medicalProfessionId }
                      ?.let { medicalProfession: MedicalProfessionModel ->
                        medicalProfession.medicalSpecialities
                          .map { it.id.value.toString() to it.name.value }
                      }

                    options = medicalSpecialitiesOptions
                    value = medicalSpecialitiesOptions?.find { it.first == uiState.data?.medicalSpeciality?.value?.toString() }?.first
                  }
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::medicalSpecialityId,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )

                labodocText {
                  label = "Lieu principal d'exercice"
                  value = uiState.data?.facilityName
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::facilityName,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.ProfessionalInformation.validateFacilityName(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.ProfessionalInformation.validateFacilityName(it.value) }
                )

                labodocText {
                  label = "Service"
                  value = uiState.data?.facilityUnit
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::facilityUnit,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.ProfessionalInformation.validateFacilityUnit(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.ProfessionalInformation.validateFacilityUnit(it.value) }
                )

                labodocSelect {
                  label = "Département"
                  options = uiState.departments
                    .map { it.code.value to "${it.code.value} - ${it.name.value}" }
                  value = uiState.data?.facilityDepartmentCode?.value
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::facilityDepartmentCode,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )

                labodocText {
                  label = "Ville"
                  value = uiState.data?.facilityCity
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::facilityCity,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.ProfessionalInformation.validateFacilityCity(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.ProfessionalInformation.validateFacilityCity(it.value) }
                )

                labodocText {
                  label = "Numéro RPPS"
                  notice = "Seulement si vous êtes concerné"
                  type = InputType.NUMBER
                  value = uiState.data?.rppsNumber?.value
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::rppsNumber,
                  required = false,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.ProfessionalInformation.validateRPPSNumber(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.ProfessionalInformation.validateRPPSNumber(it.value) }
                )

                labodocUpload {
                  label = "Déposer un justificatif"
                  notice = "Pour valider votre inscription, merci de déposer un document justifiant votre statut de professionnel de santé en exercice sur le territoire français et attestant de votre lieu d'exercice actuel."
                }.bind(
                  ViewModel.RegistrationForm.ProfessionalInformation::supportingDocument,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClickLaunch {
                    if (form.validate())
                      viewModel.submitProfessionalInformation(form.getDataWithFileContent())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalAlreadyRegistered -> {
            stepper.currentStep.setState(2)

            div(className = "health-professional-already-registered") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  Le numéro RPPS "${uiState.rppsNumber.value}" est déjà en cours d'utilisation sur la plateforme LaboDoc.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Si vous pensez qu'il s'agit d'une erreur ou d'une usurpation d'identité, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalNotFound -> {
            stepper.currentStep.setState(2)

            div(className = "health-professional-not-found") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  Après vérification auprès de l'Agence du Numérique en Santé, aucun numéro RPPS "${uiState.rppsNumber.value}" n'a été trouvé.
                """.trimIndent()
              }

              p {
                content = """
                  Si vous venez d'obtenir votre numéro RPPS, il se peut qu'un délai soit nécessaire avant que notre base de données soit mise à jour.
                """.trimIndent()
              }


              p {
                rich = true
                content = """
                  Pour plus d'informations, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.NotificationsSettingsForm -> {
            stepper.currentStep.setState(3)

            div(className = "notifications-settings-form") {
              p(className = "title") {
                rich = true
                content = """
                  Assurez une veille continue et sans effort grâce à la notification LaboDoc.
                """
              }

              val form = formPanel<ViewModel.RegistrationForm.NotificationsSettings> {
                labodocRadioGroup {
                  div {
                    p {
                      content = "Instantanéité :"
                    }
                    radio {
                      name = "notification"
                      label = "Je reçois une notification journalière uniquement si une actualité publiée me concerne."
                      value = uiState.data?.daily ?: false
                    }.bind(
                      ViewModel.RegistrationForm.NotificationsSettings::daily,
                      required = false,
                      requiredMessage = I18n.tr("Field.Required"),
                      validator = null,
                      validatorMessage = null
                    )
                  }

                  div {
                    p {
                      content = "Équilibre :"
                    }
                    radio {
                      name = "notification"
                      label = "Je reçois une notification hebdomadaire uniquement si une actualité publiée me concerne."
                      value = uiState.data?.weekly ?: true
                    }.bind(
                      ViewModel.RegistrationForm.NotificationsSettings::weekly,
                      required = false,
                      requiredMessage = I18n.tr("Field.Required"),
                      validator = null,
                      validatorMessage = null
                    )
                  }

                  div {
                    p {
                      content = "Aucune :"
                    }
                    radio {
                      name = "notification"
                      label = "Je préfère ne pas être informé(e) des actualités concernant les médicaments liés à ma pratique."
                      value = uiState.data != null && !uiState.data.daily && !uiState.data.weekly
                    }
                  }
                }

                p {
                  rich = true
                  content =
                    "<b>La pertinence avant tout !</b><br>Quel que soit votre choix, LaboDoc garantit l'envoi de notifications strictement liées à votre spécialité"
                }
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitNotificationSettings(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.AccountInformationForm -> {
            stepper.currentStep.setState(4)

            div(className = "account-information-form") {
              val form = formPanel<ViewModel.RegistrationForm.AccountInformation> {
                labodocText {
                  label = "Adresse email"
                  notice = "Ce sera votre identifiant de connexion"
                  input.autocomplete = Autocomplete.USERNAME
                  value = uiState.data?.emailAddress?.value
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::emailAddress,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.AccountInformation.validateEmailAddress(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.AccountInformation.validateEmailAddress(it.value) }
                )

                val passwordField = labodocText {
                  addCssClass("password-row")
                  label = "Mot de passe"
                  noticeRich = true
                  notice = "Doit contenir entre 8 et 32 caractères avec au moins:<ul><li>une minuscule</li><li>une majuscule<li>un chiffre</li><li>caractère spécial</li>"
                  type = InputType.PASSWORD
                  input.autocomplete = Autocomplete.NEW_PASSWORD
                  value = uiState.data?.password?.value
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::password,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.AccountInformation.validatePassword(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.AccountInformation.validatePassword(it.value) },
                )

                labodocText {
                  addCssClass("password-row")
                  label = "Confirmer votre mot de passe"
                  type = InputType.PASSWORD
                  input.autocomplete = Autocomplete.NEW_PASSWORD // https://github.com/whatwg/html/issues/3531
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::passwordConfirmation,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.AccountInformation.validateSamePassword(passwordField.value, it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.AccountInformation.validateSamePassword(passwordField.value, it.value) }
                )
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitAccountInformation(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.EmailAddressAlreadyUsed -> {
            stepper.currentStep.setState(4)

            div(className = "email-address-already-used") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  L'adresse email "${uiState.emailAddress.value}" est déjà en cours d'utilisation sur la plateforme LaboDoc.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Si vous pensez qu'il s'agit d'une erreur ou d'une usurpation d'identité, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.ComplementaryInformationForm -> {
            stepper.currentStep.setState(5)

            div(className = "complementary-information-form") {
              val form = formPanel<ViewModel.RegistrationForm.ComplementaryInformation> {
                labodocText {
                  label = "Code partenaire"
                  value = uiState.data?.partnerCode?.value
                }.bind(
                  ViewModel.RegistrationForm.ComplementaryInformation::partnerCode,
                  required = false,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.ComplementaryInformation.validatePartnerCode(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.ComplementaryInformation.validatePartnerCode(it.value) }
                )

                labodocTextArea {
                  label = "Informations complémentaires"
                  notice = "Vous pouvez saisir ici tout complément d'information vous semblant utile pour nous aider à vous identifier"
                  value = uiState.data?.comment
                }.bind(
                  ViewModel.RegistrationForm.ComplementaryInformation::comment,
                  required = false,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )
              }

              div(className = "buttons") {
                labodocButton("Demande d'inscription", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitComplementaryInformation(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
