package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.LoginRequest
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.common.Password

class UsersRepositoryImpl(
  private val apiClient: AdminApiClient
) : UsersRepository {
  override suspend fun login(
    email: EmailAddress,
    password: Password
  ): ApiResponse<String> =
    apiClient.users
      .login(LoginRequest(email, password))
      .map { it.token }

  override suspend fun logout(
  ): ApiResponse<Unit> =
    apiClient.users
      .logout()
}
