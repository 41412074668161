package fr.labodoc.webapp.pages.aboutUs

import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import org.koin.core.component.KoinComponent

class FAQPage() : SimplePanel(), KoinComponent {

  init {
    id = "page-faq"
    require("./css/pages/faq/faq.css")
    add(LabodocHeader())
    div(className = "labodoc-background-beige d-flex flex-row align-center pb-xs-6") {
      id = "page-faq-header"

      div(className = "labodoc-background-beige col-xl-6 col-lg-6 col-12") {
        id = "page-faq-presentation"

        div(className = "page-width d-flex flex-column pt-5 pt-lg-10 pt-xl-10 align-xs-center") {
          id = "page-faq-presentation-content"

          h1 (className = "mb-8 text-xs-center mb-xs-4"){
            content = "Comment pouvons-nous vous aider ?"
          }

          p (className= "mb-8 mb-xs-4") {
            rich = true
            content = "<p>Dans cette FAQ, retrouvez les questions fréquemment posées à<br> propos de la plateforme LaboDoc. Si vous ne trouvez pas la<br> réponse à votre question, n'hésitez pas à contacter notre équipe à<br> l'adresse contact@labodoc.fr</p>"
          }
        }
      }

      div (className = "col-xl-3 col-lg-3 hidden-sm-and-down") {
        div(className = "page-width") {
          image(require("./img/page-whoarewe-header.png"))
        }
      }
    }
    div(className = "d-flex flex-column justify-center mb-6 pt-xl-8 pt-lg-8") {
      id = "page-faq-general-question"
      div (className = "mx-xl-16 mx-lg-16") {
        h2(className = "my-12 mx-10 text-center general-question-subtitle") {
          rich = true
          content =
            "<p>Dans cette FAQ, retrouvez les questions fréquemment posées à propos de la plateforme LaboDoc.<br> Si vous ne trouvez pas la réponse à votre question, n'hésitez pas à contacter notre équipe à l'adresse  contact@labodoc.com</p>"
        }
        h1(className = "mb-16 mb-xs-8 mx-xl-10 mx-lg-8 mx-2") {
          content =
            "Questions fréquentes"
        }
        div(className = "faq-collapsable-container mx-xl-10 mx-lg-8 mx-2") {
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Qui peut s'inscrire sur LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "L'inscription à LaboDoc est exclusivement réservée aux professionnels de santé en exercice et aux étudiants en santé.<br>" +
                    "Actuellement, les professionnels de santé pouvant s'inscrire sont les : <br>"
                }
                ul {
                  li {
                    content = "Médecins"
                  }
                  li {
                    content = "Chirurgiens-dentistes"
                  }
                  li {
                    content = "Pharmaciens"
                  }
                  li {
                    content = "Sage-femme"
                  }
                  li {
                    content = "Infirmiers"
                  }
                }
              }

            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Comment m'inscrire sur LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Pour vous inscrire, vous devez être détenteur d'une carte de professionnel de santé (CPS) ou d'une carte de professionnel en formation (CPF). Cliquez sur ce lien ci-après pour accéder au formulaire d'inscription : <a href='/register'>je m'inscris</a> "
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Combien coûte l'accès à LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "L'accès à la plateforme et le téléchargement de l'application sont totalement gratuits pour les professionnels de santé."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "En quoi consiste LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "LaboDoc permet aux professionels de santé d'accéder simplement et librement aux informations que les laboratoires pharmaceutiques mettent à leurs dispositions sur la plateforme.<br>" +
                    "En améliorant l'accès à ces informations, LaboDoc aide les professionnels de santé à offrir à chacun de leurs patients la prise en charge la plus sûre et la mieux adaptée."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Les contenus diffusés sur LaboDoc sont-ils sécurisés et fiables ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Tous les contenus disponibles sur LaboDoc respectent scrupuleusement les dispositions du Code de la Santé Publique, incluant le cas échéant un contrôle par l'ANSM avant diffusion."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "LaboDoc est-il affilié à l'industrie pharmaceutique ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Aucune personne ayant un lien d'intérêt avec l'industrie pharmaceutique ne détient de participation dans le capital de LaboDoc."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Mes données personnelles sont-elles communiquées aux Laboratoires ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "LaboDoc stocke uniquement vos données disponibles sur l'Annuaire de Santé, nécessaires à votre identification (nom, prénom, e-mail, département et numéro RPPS)."
                }
              }
            }
            hr {  }
          }
        }
      }
    }
    div(className = "d-flex flex-xl-row flex-lg-row flex-column justify-center mb-6 pt-xl-8 pt-lg-8") {
      id = "page-faq-two-columns"
      div (className = "mr-xl-16 mr-lg-16 page-faq-column-1") {
        h1(className = "mb-16 mb-xs-8 mx-xl-10 mx-lg-8 mx-2") {
          content =
            "Accès à la plateforme Labodoc"
        }
        div(className = "faq-collapsable-container mx-xl-10 mx-lg-8 mx-2") {
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Où peut-on accéder à LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "<p>LaboDoc est accessible via le site internet <a href='https://labodoc.fr/'>www.labodoc.fr</a> ou via l'application mobile LaboDoc, disponible gratuitement sur l'App Store et Google Play. </p>"
                }
              }
            }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Qui peut s'inscrire sur LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "L'inscription à LaboDoc est exclusivement réservée aux professionnels de santé en exercice et aux étudiants en santé.<br>" +
                    "Actuellement, les professionnels de santé pouvant s'inscrire sont les : <br>"
                }
                ul {
                  li {
                    content = "Médecins"
                  }
                  li {
                    content = "Chirurgiens-dentistes"
                  }
                  li {
                    content = "Pharmaciens"
                  }
                  li {
                    content = "Sage-femme"
                  }
                  li {
                    content = "Infirmiers"
                  }
                }
              }

            }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Comment m'inscrire sur LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "<p>Pour vous inscrire, vous devez être détenteur d'une carte de professionnel de santé (CPS) ou d'une carte de professionnel en formation (CPF). Cliquez sur ce lien ci-après pour accéder au formulaire d'inscription : <a href='/register'>je m'inscris</a> </p>"
                }
              }
            }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Suis-je obligé de communiquer mon numéro de carte CPS/CPF ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "<p>Oui, LaboDoc étant une plateforme strictement réservée aux professionnels de santé, nous nous assurons de votre statut via l'Agence du Numérique en Santé. Votre numéro de carte CPS/CPF est donc nécessaire à votre identification. Vos coordonnées sont protégées et ne seront pas diffusées.</p>"
                }
              }
            }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Je n'ai pas de carte CPS/ CPF, puis-je m'inscrire sur LaboDoc ? "
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "<p>Si vous êtes un médecin exerçant en France, et que vous n'êtes pas encore inscrit au CNOM, vous devez remplir <a href='/register'>ce formulaire</a> en fournissant toutes les informations nécessaires à votre identification. Notre équipe vérifiera l'exactitude des informations et vous contactera pour finaliser votre inscription.</p>"
                }
              }
            }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Combien coûte l'accès à LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "<p>L'accès à la plateforme et le téléchargement de l'application sont totalement gratuits pour les professionnels de santé.</p>"
                }
              }
            }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Dans quelles langues l'application est-elle disponible ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "<p>L'application est disponible en Français.</p>"
                }
              }
            }
          }
        }
      }
      div (className = "mx-xl-16 mx-lg-16 page-faq-column-2") {
        h1(className = "mb-16 mb-xs-8 mx-xl-10 mx-lg-8 mx-2") {
          content =
            "Fonctionnement de LaboDoc"
        }
        div(className = "faq-collapsable-container mx-xl-10 mx-lg-8 mx-2") {
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "En quoi consiste LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "LaboDoc permet aux professionels de santé d'accéder simplement et librement aux informations que les laboratoires pharmaceutiques mettent à leurs dispositions sur la plateforme.<br>" +
                    "En améliorant l'accès à ces informations, LaboDoc aide les professionnels de santé à offrir à chacun de leurs patients la prise en charge la plus sûre et la mieux adaptée."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Les contenus diffusés sur LaboDoc sont-ils sécurisés et fiables ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Tous les contenus disponibles sur LaboDoc respectent scrupuleusement les dispositions du Code de la Santé Publique, incluant le cas échéant un contrôle par l'ANSM avant diffusion."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Qui éditent les contenus disponibles sur LaboDoc ? "
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "LaboDoc n'édite et ne modifie aucun contenu à caractère médical. \n" +
                    "Les contenus médicaux sont exclusivement fournis par les laboratoires pharmaceutiques partenaires, les sociétés savantes et les institutions."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Qui sont les Laboratoires pharmaceutiques présents sur l'application ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Les laboratoires présents sur l'application sont ceux qui ont souscrit à l'offre LaboDoc pour communiquer de manière centralisée avec les professionnels de santé."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Qui détermine le contenu que je peux voir ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Les éditeurs fixent eux-mêmes l'audience qui aura accès à leurs documents et communications conformément à la législation en vigueur."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Comment puis-je personnaliser les informations que je reçois ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Par principe, LaboDoc vous communique uniquement des informations relatives à votre spécialité. Vous pouvez cependant personnaliser d'avantage votre profil en sélectionnant des thématiques supplémentaires."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "L'entreprise LaboDoc est-elle affiliée à l'industrie pharmaceutique ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Aucune personne ayant un lien d'intérêt avec l'industrie pharmaceutique ne détient de participation dans le capital de LaboDoc."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "LaboDoc autorise-t-il le démarchage des professionnels de santé ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Non, LaboDoc n'autorise aucun démarchage ou communication non sollicitées des laboratoires envers les professionnels de santé. Seuls les professionnels de santé utilisateurs peuvent choisir de contacter les laboratoires s'ils le souhaitent."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "LaboDoc remplace-t-il l'accompagnement humain ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Non, la plateforme LaboDoc n'a pas vocation à remplacer les visiteurs médicaux. C'est un outil complémentaire aux autres canaux d'informations sur les médicaments.<br>" +
                    "Notre application permet aux professionnels de santé de s'informer de manière directe, simple et flexible, à tout moment et en tout lieu.<br>" +
                    "S'il souhaite planifier un échange, le professionnel de santé peut l'indiquer au laboratoire depuis la page de chaque médicament.<br>"
                }
              }
            }
            hr {  }
          }
        }
      }
    }
    div(className = "d-flex flex-column justify-center mb-6 pt-xl-8 pt-lg-8") {
      id = "page-faq-additional-question"
      div (className = "mx-xl-16 mx-lg-16") {
        h1(className = "mb-16 mb-xs-8 mx-xl-10 mx-lg-8 mx-2") {
          content =
            "Sécurité de vos données"
        }
        div(className = "faq-collapsable-container mx-xl-10 mx-lg-8 mx-2") {
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Quelles données personnelles sont stockées par LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "LaboDoc stocke uniquement vos données disponibles sur l'Annuaire de Santé, nécessaires à votre identification (nom, prénom, e-mail, département et numéro RPPS)."
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Mes données personnelles sont-elles communiquées aux Laboratoires ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "Non, la navigation sur LaboDoc est strictement anonyme. Aucune donnée personnelle ou statistique individuelle n'est communiquée aux laboratoires sans votre consentement explicite et éclairé (par exemple, si vous demandez expressément à être recontacté(e) par un laboratoire)"
                }
              }
            }
            hr {  }
          }
          div(className = "faq-collapsable-wrapper my-xl-10 my-lg-8 my-6") {
            var displayStatus = ObservableValue("hide")
            div(className = "d-flex flex-row justify-space-between faq-collapsable-wrapper-header").bind(displayStatus) {
              if (displayStatus.value == "show") {
                onClick { displayStatus.setState("hide")}
              } else {
                onClick { displayStatus.setState("show")}
              }
              div{
                b {
                  content = "Quels cookies utilise LaboDoc ?"
                }
              }
              div {
                if (displayStatus.value == "show") {
                  image(require("./img/Icon-awesome-minus.svg"))
                } else {
                  image(require("./img/Icon-feather-plus.svg"))
                }
              }
            }
            div(className = "faq-collapsable-wrapper-content" ).bind(displayStatus) {
              span(className = displayStatus.value) {
                p {
                  rich = true
                  content = "LaboDoc n'utilise aucun cookie. Notre plateforme est conçue pour garantir votre confidentialité et la sécurité de vos informations sans suivre votre navigation."
                }
              }
            }
            hr {  }
          }
        }
      }
    }
    div(className = "labodoc-background-white px-lg-12 px-xl-16 py-10") {
      id = "page-faq-contact"

      div(className = "bubble mx-lg-12 mx-xl-16") {
        div (className = "d-flex flex-column justify-center align-center text-center my-3") {
          h2 {
            content = "J'ai d'autres questions et j'aimerais échanger avec quelqu'un."
          }
        }

        div (className = "d-flex flex-column justify-center align-center text-center") {
          p (className = "px-lg-10 px-xl-14") {
            rich = true
            content = "Vous pouvez contacter notre équipe à l'adresse <a href='emailto:contact@labodoc.com'>contact@labodoc.com</a>et nous vous répondrons sous 2 à 3 jours ouvrés (hors weekend et jours fériés)."
          }
        }
      }
    }
  }
}

fun Container.fAQPage() {
  add(FAQPage())
  add(LabodocFooter())
}
