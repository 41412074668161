package fr.labodoc.webapp.pages.healthProfessional.profile

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.serializers.PasswordAsString
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.repository.DepartmentsRepository
import fr.labodoc.app.data.healthprofessional.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import io.kvision.core.Container
import io.kvision.form.formPanel
import io.kvision.form.text.Text
import io.kvision.html.InputType
import io.kvision.html.div
import io.kvision.html.p
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class HealthProfessionalProfileInformationPage : SimplePanel() {
  private sealed class ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data class Loaded(
        val firstName: String,
        val lastName: String,
        val rppsNumber: String?,
        val medicalProfession: String,
        val medicalSpeciality: String,
        val department: String,
        val email: String
      ) : UiState()

      data object Error : UiState()
    }

    abstract val uiState: ObservableState<UiState>

    abstract val changePasswordResponse: ObservableState<ApiResponse<Unit>?>

    abstract fun changePassword(oldPassword: Password, newPassword: Password)
  }

  private class ViewModelImpl : ViewModel(), KoinComponent {
    private val userRepository: UsersRepository by inject()

    override val uiState: ObservableValue<UiState> by lazy {
      val observableValue: ObservableValue<UiState> = ObservableValue(UiState.Loading)

      App.scope.launch {
        either {
          val self = userRepository.getSelf()
            .mapLeft { UiState.Error }
            .bind()

          ensure(self is HealthProfessionalUserModel) { UiState.Error }

          UiState.Loaded(
            firstName = self.firstName.value,
            lastName = self.lastName.value,
            rppsNumber = self.rppsNumber?.value,
            medicalProfession = self.medicalProfession.name.value,
            medicalSpeciality = self.medicalSpeciality.name.value,
            department = "${self.department.name.value} (${self.department.code.value})",
            email = self.emailAddress.value
          )
        }
          .fold({ it }, { it }) // Is there a better way to merge ?
          .let { observableValue.setState(it) }
      }

      observableValue
    }

    override val changePasswordResponse: ObservableValue<ApiResponse<Unit>?> =
      ObservableValue(null)

    override fun changePassword(oldPassword: Password, newPassword: Password) {
      App.scope.launch {
        changePasswordResponse.setState(userRepository.changePassword(oldPassword, newPassword))
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  private val modifyPasswordModal = ChangePasswordModal(
    onFormValidation = { viewModel.changePassword(it.currentPassword, it.newPassword) }
  )

  init {
    id = "page-health-professional-profile-information"
    require("./css/pages/healthProfessional/profile/information.css")

    div(className = "page-width") {
      viewModel.changePasswordResponse.subscribe {
        when (it) {
          is Either.Left -> {
            val error = it.value

            when (error.code) {
              Errors.Authentication.InvalidCredentials.code -> Toast.danger("Mot de passe invalide")
              else -> Toast.danger("Une erreur est survenue, veuillez réessayer")
            }
          }

          is Either.Right -> {
            Toast.success("Mot de passe correctement mis à jour")
            modifyPasswordModal.hide()
          }

          null -> {}
        }
      }

      bind(viewModel.uiState) {
        when (it) {
          ViewModel.UiState.Loading -> labodocSpinner()

          ViewModel.UiState.Error -> {
            Toast.danger("Une erreur est survenue")
            //window.history.back()
          }

          is ViewModel.UiState.Loaded -> {
            div(className = "coordinates") {
              p(className = "title") {
                content = "Mes coordonnées"
              }

              p {
                rich = true
                content =
                  """
                   Si la spécialité qui vous est attribuée n'est plus celle que vous exercez actuellement, <b><a href="${Page.Contact().url}" target="_blank">contactez-nous</a></b> afin de mettre à jour vos informations.
                  """.trimIndent()
              }

              div(className = "container") {
                labodocText {
                  addCssClass("first-name")
                  label = "Prénom"
                  value = it.firstName
                  disabled = true
                }

                labodocText {
                  addCssClass("last-name")
                  label = "Nom"
                  value = it.lastName
                  disabled = true
                }

                labodocText {
                  addCssClass("rpps-number")
                  label = "Numéro RPPS"
                  value = it.rppsNumber
                  disabled = true
                }

                labodocText {
                  addCssClass("medical-profession")
                  label = "Profession"
                  value = it.medicalProfession
                  disabled = true
                }

                labodocText {
                  addCssClass("medical-speciality")
                  label = "Spécialité"
                  value = it.medicalSpeciality
                  disabled = true
                }

                labodocText {
                  addCssClass("department")
                  label = "Département"
                  value = it.department
                  disabled = true
                }
              }
            }

            div(className = "identifier") {
              p(className = "title") {
                content = "Mon identifiant"
              }

              div(className = "container") {
                labodocText {
                  addCssClass("email")
                  label = "Email"
                  value = it.email
                  disabled = true
                }

                labodocButton("Modifier mon mot de passe", className = "labodoc-background-middle-blue") {
                  onClick {
                    modifyPasswordModal.show()
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.healthProfessionalProfileInformationPage(): HealthProfessionalProfileInformationPage {
  val healthProfessionalProfileInformationPage = HealthProfessionalProfileInformationPage()
  this.add(healthProfessionalProfileInformationPage)
  return healthProfessionalProfileInformationPage
}

class ChangePasswordModal(
  onFormValidation: (ChangePasswordFormData) -> Unit
) {
  @Serializable
  data class ChangePasswordFormData(
    val currentPassword: PasswordAsString,
    val newPassword: PasswordAsString,
    val newPasswordConfirmation: PasswordAsString
  ) {
    companion object {
      fun validatePassword(password: Text): String? = Password(password.value ?: "").fold(
        {
          when (it) {
            Errors.Password.Invalid.TooShort -> "Trop court"
            Errors.Password.Invalid.TooLong -> "Trop long"
            Errors.Password.Invalid.NoLowercaseLetter -> "Pas de minuscule"
            Errors.Password.Invalid.NoUppercaseLetter -> "Pas de majuscule"
            Errors.Password.Invalid.NoDigit -> "Pas de chiffre"
            Errors.Password.Invalid.NoSpecialCharacter -> "Pas de caractère special"
          }
        },
        { null }
      )

      fun validateSamePassword(password: Text, passwordConfirmation: Text): String? {
        if (password.value != passwordConfirmation.value) {
          return "Les mots de passe ne sont pas identiques"
        }
        return null
      }
    }
  }

  private val currentPasswordField =
    LabodocText(label = "Mot de passe actuel", type = InputType.PASSWORD)

  private val newPasswordField =
    LabodocText(label = "Nouveau mot de passe", type = InputType.PASSWORD) {
      noticeRich = true
      notice =
        "Doit contenir entre 8 et 32 caractères avec au moins:<ul><li>une minuscule</li><li>une majuscule<li>un chiffre</li><li>caractère spécial</li>"
    }

  private val newPasswordConfirmationField =
    LabodocText(label = "Confirmer votre nouveau mot de passe", type = InputType.PASSWORD)

  private val modifyPasswordModal = LabodocPopup(
    closeButton = true,
    className = "modify-password-modal",
    content = {
      p(className = "title") {
        content = "Vous souhaitez modifier votre mot de passe ?"
      }

      val changePasswordForm = formPanel<ChangePasswordFormData> {
        addCustom(
          ChangePasswordFormData::currentPassword,
          currentPasswordField,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validatorMessage = { ChangePasswordFormData.validatePassword(it) },
          validator = { ChangePasswordFormData.validatePassword(it) == null }
        )

        addCustom(
          ChangePasswordFormData::newPassword,
          newPasswordField,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validatorMessage = { ChangePasswordFormData.validatePassword(it) },
          validator = { ChangePasswordFormData.validatePassword(it) == null }
        )

        addCustom(
          ChangePasswordFormData::newPasswordConfirmation,
          newPasswordConfirmationField,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validatorMessage = { ChangePasswordFormData.validateSamePassword(newPasswordField, it) },
          validator = { ChangePasswordFormData.validateSamePassword(newPasswordField, it) == null }
        )
      }

      labodocButton("Valider", className = "labodoc-background-yellow") {
        onClick {
          if (changePasswordForm.validate())
            onFormValidation(changePasswordForm.getData())
        }
      }
    }
  )

  fun show() {
    modifyPasswordModal.show()
  }

  fun hide() {
    modifyPasswordModal.hide()
    currentPasswordField.value = null
    newPasswordField.value = null
    newPasswordConfirmationField.value = null
  }
}
