package fr.labodoc.webapp.pages.register.sections

import arrow.core.*
import arrow.core.raise.*
import arrow.fx.coroutines.parZipOrAccumulate
import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.healthprofessional.model.DepartmentModel
import fr.labodoc.app.data.healthprofessional.model.MedicalDiplomaModel
import fr.labodoc.app.data.healthprofessional.model.MedicalProfessionAndSpecialityModel
import fr.labodoc.app.data.healthprofessional.model.UniversityHospitalModel
import fr.labodoc.app.data.healthprofessional.repository.*
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.register.RegisterPage
import fr.labodoc.webapp.pages.register.sections.WithCardForm.ViewModel.ProfessionalInformation
import io.kvision.core.Display
import io.kvision.core.StringPair
import io.kvision.core.style
import io.kvision.form.check.radio
import io.kvision.form.formPanel
import io.kvision.html.*
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class WithCardForm(
  state: MutableState<RegisterPage.State>
) : SimplePanel(className = "with-card-form") {
  sealed interface ViewModel {
    sealed class UIState {
      data class MedicalCardInformationForm(
        val data: MedicalCardInformation?
      ) : UIState()

      data class MedicalCardNotFound(
        val medicalCardNumber: MedicalCardNumber
      ): UIState()

      data class MedicalCardExpired(
        val medicalCardNumber: MedicalCardNumber
      ): UIState()

      data class HealthProfessionalAlreadyRegistered(
        val medicalCardNumber: MedicalCardNumber
      ): UIState()

      data class HealthProfessionalInformationCpsForm(
        val data: ProfessionalInformation.Cps?,
        val departmentOptions: List<StringPair>,
        val situations: NonEmptyList<HealthProfessionalSituation>
      ) : UIState()

      data class HealthProfessionalInformationCpfForm(
        val data: ProfessionalInformation.Cpf?,
        val medicalDiplomaOptions: List<StringPair>,
        val universityHospitalOptions: List<StringPair>
      ) : UIState()

      data object HealthProfessionalNotEligible: UIState()

      data class InformationRecap(
        val cardNumber: MedicalCardNumber,
        val rppsNumber: RPPSNumber,
        val lastName: LastName,
        val firstName: FirstName,
        val labodocMedicalProfessionName: MedicalProfessionName,
        val labodocMedicalSpecialityName: MedicalSpecialityName,
        val professionalCategoryName: ProfessionalCategoryName,
        val professionalStatusName: ProfessionalStatusName,
        val departmentCode: DepartmentCode,
        val departmentName: DepartmentName
      ) : UIState()

      data class NotificationSettingsForm(
        val data: NotificationsSettings?
      ) : UIState()

      data class AccountInformationForm(
        val data: AccountInformation?
      ) : UIState()

      data class EmailAddressAlreadyUsed(
        val emailAddress: EmailAddress
      ): UIState()
    }

    data class MedicalCardInformation(
      val type: MedicalCardTypeCode,
      val number: MedicalCardNumber
    )

    sealed class ProfessionalInformation {
      abstract val situation: HealthProfessionalSituation

      data class Cps(
        override val situation: HealthProfessionalSituation,
        val department: DepartmentModel,
        val labodocMedicalProfessionAndSpeciality: MedicalProfessionAndSpecialityModel
      ) : ProfessionalInformation()

      data class Cpf(
        override val situation: HealthProfessionalSituation,
        val medicalDiploma: MedicalDiplomaModel,
        val universityHospital: UniversityHospitalModel
      ) : ProfessionalInformation()
    }

    data class NotificationsSettings(
      val daily: Boolean,
      val weekly: Boolean
    )

    data class AccountInformation(
      val emailAddress: EmailAddress,
      val password: Password,
      val partnerCode: PartnerCode?
    )

    class RegistrationForm {
      @Serializable
      data class MedicalCardInformation(
        val medicalCardNumber: String? = null
      ) {
        companion object {
          fun validateMedicalCardNumber(medicalCardNumber: String?): String? =
            MedicalCardNumber(medicalCardNumber ?: "")
              .fold(
                { error: Errors.MedicalCard.Number.Invalid ->
                  when (error) {
                    Errors.MedicalCard.Number.Invalid.Format -> "Le format est invalide"
                  }
                },
                {
                  null
                }
              )
        }
      }

      sealed class HealthProfessionalInformation {
        @Serializable
        data class Cps(
          val situationIndex: String? = null,
          val department: String? = null
        ) : HealthProfessionalInformation()

        @Serializable
        data class Cpf(
          val medicalDiploma: String? = null,
          val universityHospital: String? = null
        ) : HealthProfessionalInformation()
      }

      @Serializable
      data class NotificationsSettings(
        val daily: Boolean = false,
        val weekly: Boolean = false
      )

      @Serializable
      data class AccountInformation(
        val emailAddress: String? = null,
        val password: String? = null,
        val passwordConfirmation: String? = null,
        val partnerCode: String? = null
      ) {
        companion object {
          fun validateEmailAddress(emailAddress: String?): String? = EmailAddress(emailAddress ?: "").fold(
            {
              I18n.tr("Errors.EmailAddress.Invalid")
            },
            {
              null
            }
          )

          fun validatePassword(password: String?): String? = Password(password ?: "").fold(
            { error: Errors.Password.Invalid ->
              when (error) {
                Errors.Password.Invalid.TooShort -> "Trop court"
                Errors.Password.Invalid.TooLong -> "Trop long"
                Errors.Password.Invalid.NoLowercaseLetter -> "Pas de minuscule"
                Errors.Password.Invalid.NoUppercaseLetter -> "Pas de majuscule"
                Errors.Password.Invalid.NoDigit -> "Pas de chiffre"
                Errors.Password.Invalid.NoSpecialCharacter -> "Pas de caractère special"
              }
            },
            { null }
          )

          fun validateSamePassword(password: String?, passwordConfirmation: String?): String? {
            return if (password != passwordConfirmation)
              "Les mots de passe ne sont pas identiques"
            else
              null
          }

          fun validatePartnerCode(partnerCode: String?): String? =
            partnerCode?.let {
              PartnerCode(partnerCode).fold(
                { error: Errors.Partner.Code.Invalid ->
                  when (error) {
                    Errors.Partner.Code.Invalid.Blank -> "Ne peut pas être vide si renseigné"
                    Errors.Partner.Code.Invalid.TooLong -> "Trop long"
                  }
                },
                {
                  null
                }
              )
            }
        }
      }
    }

    data class HealthProfessionalSituation(
      val rppsNumber: RPPSNumber,
      val firstName: FirstName,
      val lastName: LastName,
      val healthDirectoryProfessionCode: ProfessionCode,
      val healthDirectoryProfessionName: ProfessionName,
      val healthDirectoryProfessionalCategoryCode: ProfessionalCategoryCode,
      val healthDirectoryProfessionalCategoryName: ProfessionalCategoryName,
      val healthDirectoryProfessionalStatusCode: ProfessionalStatusCode,
      val healthDirectoryProfessionalStatusName: ProfessionalStatusName,
      val healthDirectoryExpertiseCode: ExpertiseCode?,
      val healthDirectoryExpertiseName: ExpertiseName?,
      val healthDirectoryPharmacistTableSectionCode: PharmacistTableSection.Code?,
      val facility: Facility?
    ) {
      data class Facility(
        val name: String,
        val department: DepartmentCode
      )
    }

    val uiState: ObservableState<UIState>

    fun goBack()

    fun submitMedicalCardInformationForm(medicalCardInformation: RegistrationForm.MedicalCardInformation)

    fun submitHealthProfessionalInformationForm(healthProfessionalInformation: RegistrationForm.HealthProfessionalInformation)

    fun validateInformation()

    fun submitNotificationSettingsForm(notificationsSettings: RegistrationForm.NotificationsSettings)

    fun submitAccountInformationForm(accountInformation: RegistrationForm.AccountInformation)
  }

  private class ViewModelImpl(
    private val registerPageState: MutableState<RegisterPage.State>
  ) : ViewModel, KoinComponent {
    private val healthDirectoryRepository: HealthDirectoryRepository by inject()
    private val userRepository: UsersRepository by inject()
    private val medicalDiplomaRepository: MedicalDiplomasRepository by inject()
    private val universityHospitalRepository: UniversityHospitalsRepository by inject()
    private val departmentRepository: DepartmentsRepository by inject()

    override val uiState: ObservableValue<ViewModel.UIState> =
      ObservableValue(ViewModel.UIState.MedicalCardInformationForm(null))

    private var medicalCardInformation: ViewModel.MedicalCardInformation? = null
    private var healthProfessionalSituations: NonEmptyList<ViewModel.HealthProfessionalSituation>? = null
    private var professionalInformation: ProfessionalInformation? = null
    private var notificationsSettings: ViewModel.NotificationsSettings? = null
    private var accountInformation: ViewModel.AccountInformation? = null

    private var departments: Map<DepartmentCode, DepartmentModel>? = null
    private var universityHospitals: Map<UniversityHospitalId, UniversityHospitalModel>? = null
    private var medicalDiplomas: Map<MedicalDiplomaId, MedicalDiplomaModel>? = null

    override fun goBack() {
      App.scope.launch {
        when (uiState.value) {
          is ViewModel.UIState.MedicalCardInformationForm -> {
            registerPageState.setState(RegisterPage.State.Home)
          }

          is ViewModel.UIState.MedicalCardNotFound,
          is ViewModel.UIState.MedicalCardExpired,
          is ViewModel.UIState.HealthProfessionalAlreadyRegistered,
          is ViewModel.UIState.HealthProfessionalInformationCpsForm,
          is ViewModel.UIState.HealthProfessionalInformationCpfForm -> {
            val newUiState = ViewModel.UIState.MedicalCardInformationForm(
              data = medicalCardInformation
            )

            uiState.setState(newUiState)
          }

          is ViewModel.UIState.HealthProfessionalNotEligible,
          is ViewModel.UIState.InformationRecap -> {
            val newUiState = professionalInformation?.let { professionalInformation ->
              either {
                when (professionalInformation) {
                  is ProfessionalInformation.Cps -> {
                    val departmentOptions = departments
                      ?.map { it.value.code.value to it.value.name.value }

                    ViewModel.UIState.HealthProfessionalInformationCpsForm(
                      data = professionalInformation,
                      departmentOptions = ensureNotNull(departmentOptions) {},
                      situations = ensureNotNull(healthProfessionalSituations) {}
                    )
                  }

                  is ProfessionalInformation.Cpf -> {
                    val medicalDiplomaOptions = medicalDiplomas
                      ?.map { it.value.id.value.toString() to it.value.name.value }

                    val universityHospitalOptions = universityHospitals
                      ?.map { it.value.id.value.toString() to it.value.name.value }

                    ViewModel.UIState.HealthProfessionalInformationCpfForm(
                      data = professionalInformation,
                      medicalDiplomaOptions = ensureNotNull(medicalDiplomaOptions) {},
                      universityHospitalOptions = ensureNotNull(universityHospitalOptions) {}
                    )
                  }
                }
              }.fold(
                {
                  ViewModel.UIState.MedicalCardInformationForm(
                    data = medicalCardInformation
                  )
                },
                { newUiState: ViewModel.UIState ->
                  newUiState
                }
              )
            } ?: ViewModel.UIState.MedicalCardInformationForm(
              data = medicalCardInformation
            )

            uiState.setState(newUiState)
          }

          is ViewModel.UIState.NotificationSettingsForm -> {
            val newUiState = when (val truc = professionalInformation) {
              is ProfessionalInformation.Cps -> {
                either {
                  ViewModel.UIState.InformationRecap(
                    cardNumber = ensureNotNull(medicalCardInformation?.number) {},
                    rppsNumber = truc.situation.rppsNumber,
                    lastName = truc.situation.lastName,
                    firstName = truc.situation.firstName,
                    labodocMedicalProfessionName = truc.labodocMedicalProfessionAndSpeciality.medicalProfessionName,
                    labodocMedicalSpecialityName = truc.labodocMedicalProfessionAndSpeciality.medicalSpecialityName,
                    professionalCategoryName = truc.situation.healthDirectoryProfessionalCategoryName,
                    professionalStatusName = truc.situation.healthDirectoryProfessionalStatusName,
                    departmentCode = truc.department.code,
                    departmentName = truc.department.name
                  )
                }.fold(
                  {
                    ViewModel.UIState.MedicalCardInformationForm(
                      data = medicalCardInformation
                    )
                  },
                  {
                    it
                  }
                )
              }

              is ProfessionalInformation.Cpf -> {
                either {
                  ViewModel.UIState.InformationRecap(
                    cardNumber = ensureNotNull(medicalCardInformation?.number) {},
                    rppsNumber = truc.situation.rppsNumber,
                    lastName = truc.situation.lastName,
                    firstName = truc.situation.firstName,
                    labodocMedicalProfessionName = truc.medicalDiploma.labodocMedicalSpeciality.medicalProfession.name,
                    labodocMedicalSpecialityName = truc.medicalDiploma.labodocMedicalSpeciality.name,
                    professionalCategoryName = ProfessionalCategoryName("Étudiant"),
                    professionalStatusName = ProfessionalStatusName("Salarié"),
                    departmentCode = truc.universityHospital.department.code,
                    departmentName = truc.universityHospital.department.name
                  )
                }.fold(
                  {
                    ViewModel.UIState.MedicalCardInformationForm(
                      data = medicalCardInformation
                    )
                  },
                  {
                    it
                  }
                )
              }

              null -> ViewModel.UIState.MedicalCardInformationForm(
                data = medicalCardInformation
              )
            }

            uiState.setState(newUiState)
          }

          is ViewModel.UIState.AccountInformationForm -> {
            val newUiState = ViewModel.UIState.NotificationSettingsForm(
              data = notificationsSettings
            )

            uiState.setState(newUiState)
          }

          is ViewModel.UIState.EmailAddressAlreadyUsed -> {
            val newUiState = ViewModel.UIState.AccountInformationForm(
              data = accountInformation
            )

            uiState.setState(newUiState)
          }
        }
      }
    }

    override fun submitMedicalCardInformationForm(medicalCardInformation: ViewModel.RegistrationForm.MedicalCardInformation) {
      App.scope.launch {
        val newUiState = either {
          ensureNotNull(medicalCardInformation.medicalCardNumber) {
            Toast.danger("Le numéro de carte ne peut pas être vide")
            ViewModel.UIState.MedicalCardInformationForm(
              data = this@ViewModelImpl.medicalCardInformation
            )
          }
          val medicalCardNumber = MedicalCardNumber(medicalCardInformation.medicalCardNumber)
            .mapLeft { error: Errors.MedicalCard.Number.Invalid ->
              when (error) {
                Errors.MedicalCard.Number.Invalid.Format -> {
                  Toast.danger("Le format du numéro de carte est invalide")
                  ViewModel.UIState.MedicalCardInformationForm(
                    data = this@ViewModelImpl.medicalCardInformation
                  )
                }
              }
            }
            .bind()

          val healthProfessional = userRepository.getHealthProfessionalByCardNumber(medicalCardNumber)
            .mapLeft { errorResponse ->
              when (errorResponse.code) {
                Errors.MedicalCard.NotFound().code -> {
                  ViewModel.UIState.MedicalCardNotFound(
                    medicalCardNumber = medicalCardNumber
                  )
                }
                Errors.MedicalCard.Expired().code -> {
                  ViewModel.UIState.MedicalCardExpired(
                    medicalCardNumber = medicalCardNumber
                  )
                }
                Errors.RPPSNumber.AlreadyUsed.code -> {
                  ViewModel.UIState.HealthProfessionalAlreadyRegistered(
                    medicalCardNumber = medicalCardNumber
                  )
                }
                else -> {
                  Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                  ViewModel.UIState.MedicalCardInformationForm(
                    data = this@ViewModelImpl.medicalCardInformation
                  )
                }
              }
            }
            .bind()

          // Handle the case someone use a different card so is being someone else and should not have prefilled fields
          if (healthProfessional.medicalCard.number != this@ViewModelImpl.medicalCardInformation?.number) {
            this@ViewModelImpl.healthProfessionalSituations = null
            this@ViewModelImpl.professionalInformation = null
            this@ViewModelImpl.notificationsSettings = null
            this@ViewModelImpl.accountInformation = null
          }

          this@ViewModelImpl.medicalCardInformation = ViewModel.MedicalCardInformation(
            type = healthProfessional.medicalCard.typeCode,
            number = healthProfessional.medicalCard.number
          )

          val rppsNumber = healthProfessional.nationalIdentifier.toRPPSNumber()
            .mapLeft { error ->
              when (error) {
                Errors.RPPSNumber.Invalid.Format -> {
                  ViewModel.UIState.HealthProfessionalNotEligible
                }
              }
            }
            .bind()

          val healthProfessionalSituations = healthProfessional.practices
            .mapNotNull { practice ->
              if (
                (practice.professionalCategoryCode.value == "C" && healthProfessional.medicalCard.typeCode.value == "CPS") ||
                (practice.professionalCategoryCode.value == "M" && healthProfessional.medicalCard.typeCode.value == "CPS") ||
                (practice.professionalCategoryCode.value == "E" && healthProfessional.medicalCard.typeCode.value == "CPF")
                ) {
                practice.situations
                  .map { situation ->
                    ViewModel.HealthProfessionalSituation(
                      rppsNumber = rppsNumber,
                      firstName = practice.practiceFirstName,
                      lastName = practice.practiceLastName,
                      healthDirectoryProfessionCode = practice.professionCode,
                      healthDirectoryProfessionName = practice.professionName,
                      healthDirectoryProfessionalCategoryCode = practice.professionalCategoryCode,
                      healthDirectoryProfessionalCategoryName = practice.professionalCategoryName,
                      healthDirectoryProfessionalStatusCode = situation.professionalStatusCode,
                      healthDirectoryProfessionalStatusName = situation.professionalStatusName,
                      healthDirectoryExpertiseCode = practice.expertises.firstOrNull()?.code,
                      healthDirectoryExpertiseName = practice.expertises.firstOrNull()?.name,
                      healthDirectoryPharmacistTableSectionCode = situation.pharmacistTableSectionCode,
                      facility = situation.facility?.let { facility ->
                        ViewModel.HealthProfessionalSituation.Facility(
                          name = facility.name?.let { "$it - ${facility.officeCedex}" } ?: facility.officeCedex,
                          department = facility.departmentCode
                        )
                      }
                    )
                  }
              } else
                null
            }
            .flatten()
            .toNonEmptyListOrNull()



          if (healthProfessional.medicalCard.typeCode.value == "CPF") {
            val firstSituation: ViewModel.HealthProfessionalSituation? = healthProfessionalSituations?.firstOrNull()
              ?: healthProfessional.practices.firstOrNull()?.let { practice ->
                ViewModel.HealthProfessionalSituation(
                  rppsNumber = rppsNumber,
                  firstName = practice.practiceFirstName,
                  lastName = practice.practiceLastName,
                  healthDirectoryProfessionCode = practice.professionCode,
                  healthDirectoryProfessionName = practice.professionName,
                  healthDirectoryProfessionalCategoryCode = practice.professionalCategoryCode,
                  healthDirectoryProfessionalCategoryName = practice.professionalCategoryName,
                  healthDirectoryProfessionalStatusCode = ProfessionalStatusCode("S"),
                  healthDirectoryProfessionalStatusName = ProfessionalStatusName("Salarié"),
                  healthDirectoryExpertiseCode = null,
                  healthDirectoryExpertiseName = null,
                  healthDirectoryPharmacistTableSectionCode = null,
                  facility = null
                )
              }

            ensureNotNull(firstSituation) {
              ViewModel.UIState.HealthProfessionalNotEligible
            }

            this@ViewModelImpl.healthProfessionalSituations = nonEmptyListOf(firstSituation)

            val medicalDiplomas =
              medicalDiplomaRepository.getMedicalDiplomas(firstSituation.healthDirectoryProfessionCode)
                .mapLeft {
                  Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                  ViewModel.UIState.MedicalCardInformationForm(
                    data = this@ViewModelImpl.medicalCardInformation
                  )
                }
                .bind()

            ensure(medicalDiplomas.isNotEmpty()) {
              ViewModel.UIState.HealthProfessionalNotEligible
            }

            this@ViewModelImpl.medicalDiplomas = medicalDiplomas.associateBy { it.id }

            val universityHospitals = universityHospitalRepository.getUniversityHospitals()
              .mapLeft {
                Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                ViewModel.UIState.MedicalCardInformationForm(
                  data = this@ViewModelImpl.medicalCardInformation
                )
              }
              .bind()
            this@ViewModelImpl.universityHospitals = universityHospitals.associateBy { it.id }

            ViewModel.UIState.HealthProfessionalInformationCpfForm(
              data = professionalInformation as? ProfessionalInformation.Cpf,
              medicalDiplomaOptions = medicalDiplomas
                .sortedBy { it.name.value }
                .map { it.id.value.toString() to it.name.value },
              universityHospitalOptions = universityHospitals
                .sortedBy { it.department.code.value }
                .map { it.id.value.toString() to "${it.department.code.value} - ${it.name.value}" }
            )
          } else {
            ensureNotNull(healthProfessionalSituations) {
              ViewModel.UIState.HealthProfessionalNotEligible
            }
            this@ViewModelImpl.healthProfessionalSituations = healthProfessionalSituations

            val departments = departmentRepository.getDepartments()
              .mapLeft {
                Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                ViewModel.UIState.MedicalCardInformationForm(
                  data = this@ViewModelImpl.medicalCardInformation
                )
              }
              .bind()
            this@ViewModelImpl.departments = departments.associateBy { it.code }

            ViewModel.UIState.HealthProfessionalInformationCpsForm(
              data = professionalInformation as? ProfessionalInformation.Cps,
              departmentOptions = departments
                .map { it.code.value to "${it.code.value} - ${it.name.value}" },
              situations = healthProfessionalSituations
            )
          }
        }.fold({it}, {it})

        uiState.setState(newUiState)
      }
    }

    override fun submitHealthProfessionalInformationForm(healthProfessionalInformation: ViewModel.RegistrationForm.HealthProfessionalInformation) {
      App.scope.launch {
        either<List<String>, Unit> {
          when (healthProfessionalInformation) {
            is ViewModel.RegistrationForm.HealthProfessionalInformation.Cps -> {
              zipOrAccumulate(
                {
                  ensureNotNull(medicalCardInformation) { "Les informations de la carte ne sont pas renseignées" }
                },
                {
                  ensureNotNull(healthProfessionalSituations?.getOrNull(healthProfessionalInformation.situationIndex?.toInt() ?: -1)) { "Impossible de déterminer les informations du professionel de santé" }
                },
                {
                  ensureNotNull(healthProfessionalInformation.department) { "Le champ département est obligatoire" }

                  val departmentCode = DepartmentCode(healthProfessionalInformation.department)
                    .mapLeft { error: Errors.Department.Code.Invalid ->
                      when (error) {
                        Errors.Department.Code.Invalid.Format -> "Le champ département est invalide"
                      }
                    }
                    .bind()

                  ensureNotNull(departments?.get(departmentCode)) { "Département inconnu" }
                }
              ) { medicalCardInformation, situation, department ->
                val labodocMedicalProfessionAndSpecialityResponse = healthDirectoryRepository.getMatchingLabodocMedicalSpeciality(
                  profession = situation.healthDirectoryProfessionCode,
                  professionalCategory = situation.healthDirectoryProfessionalCategoryCode,
                  professionalStatus = situation.healthDirectoryProfessionalStatusCode,
                  expertise = situation.healthDirectoryExpertiseCode,
                  pharmacistTableSection = situation.healthDirectoryPharmacistTableSectionCode
                )

                when (labodocMedicalProfessionAndSpecialityResponse) {
                  is Either.Left -> {
                    val error = labodocMedicalProfessionAndSpecialityResponse.value

                    when (error.code) {
                      Errors.HealthDirectoryMatchingRule.NoMatch.code -> {
                        val newUiState = ViewModel.UIState.HealthProfessionalNotEligible

                        uiState.setState(newUiState)
                      }

                      else -> {
                        Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                      }
                    }
                  }
                  is Either.Right -> {
                    val labodocMedicalProfessionAndSpeciality = labodocMedicalProfessionAndSpecialityResponse.value

                    this@ViewModelImpl.professionalInformation = ProfessionalInformation.Cps(
                      situation = situation,
                      department = department,
                      labodocMedicalProfessionAndSpeciality = labodocMedicalProfessionAndSpeciality
                    )

                    val newUiState = ViewModel.UIState.InformationRecap(
                      cardNumber = medicalCardInformation.number,
                      rppsNumber = situation.rppsNumber,
                      lastName = situation.lastName,
                      firstName = situation.firstName,
                      labodocMedicalProfessionName = labodocMedicalProfessionAndSpeciality.medicalProfessionName,
                      labodocMedicalSpecialityName = labodocMedicalProfessionAndSpeciality.medicalSpecialityName,
                      professionalCategoryName = situation.healthDirectoryProfessionalCategoryName,
                      professionalStatusName = situation.healthDirectoryProfessionalStatusName,
                      departmentCode = department.code,
                      departmentName = department.name
                    )

                    uiState.setState(newUiState)
                  }
                }
              }
            }

            is ViewModel.RegistrationForm.HealthProfessionalInformation.Cpf -> {
              zipOrAccumulate(
                {
                  ensureNotNull(medicalCardInformation) { "Les informations de la carte ne sont pas renseignées" }
                },
                {
                  val situations = ensureNotNull(healthProfessionalSituations) { "Impossible de déterminer les informations du professionel de santé" }
                  situations.first()
                },
                {
                  ensureNotNull(healthProfessionalInformation.medicalDiploma) { "Le champ diplôme médical est obligatoire" }

                  val medicalDiplomaId = catch({
                    MedicalDiplomaId(uuidFrom(healthProfessionalInformation.medicalDiploma))
                  }) {
                    raise("Le champ diplôme médical est invalide")
                  }

                  ensureNotNull(medicalDiplomas?.get(medicalDiplomaId)) { "Diplôme médical inconnu" }
                },
                {
                  ensureNotNull(healthProfessionalInformation.universityHospital) { "Le champ CHU est obligatoire" }

                  val universityHospitalId = catch({
                    UniversityHospitalId(uuidFrom(healthProfessionalInformation.universityHospital))
                  }) {
                    raise("Le champ CHU est invalide")
                  }

                  ensureNotNull(universityHospitals?.get(universityHospitalId)) { "CHU inconnu" }
                }
              ) { medicalCardInformation, situation, medicalDiploma, universityHospital ->
                this@ViewModelImpl.professionalInformation = ProfessionalInformation.Cpf(
                  situation = situation,
                  medicalDiploma = medicalDiploma,
                  universityHospital = universityHospital
                )

                val newUiState = ViewModel.UIState.InformationRecap(
                  cardNumber = medicalCardInformation.number,
                  rppsNumber = situation.rppsNumber,
                  lastName = situation.lastName,
                  firstName = situation.firstName,
                  labodocMedicalProfessionName = medicalDiploma.labodocMedicalSpeciality.medicalProfession.name,
                  labodocMedicalSpecialityName = medicalDiploma.labodocMedicalSpeciality.name,
                  professionalCategoryName = ProfessionalCategoryName("Étudiant"),
                  professionalStatusName = ProfessionalStatusName("Salarié"),
                  departmentCode = universityHospital.department.code,
                  departmentName = universityHospital.department.name
                )

                uiState.setState(newUiState)
              }
            }
          }
        }.onLeft { errorMessages: List<String> ->
          errorMessages.forEach { errorMessage: String ->
            Toast.danger(errorMessage)
          }
        }
      }
    }

    override fun validateInformation() {
      App.scope.launch {
        val newUiState = ViewModel.UIState.NotificationSettingsForm(
          data = this@ViewModelImpl.notificationsSettings
        )

        uiState.setState(newUiState)
      }
    }

    override fun submitNotificationSettingsForm(notificationsSettings: ViewModel.RegistrationForm.NotificationsSettings) {
      App.scope.launch {
        this@ViewModelImpl.notificationsSettings = ViewModel.NotificationsSettings(
          daily = notificationsSettings.daily,
          weekly = notificationsSettings.weekly
        )

        val newUiState = ViewModel.UIState.AccountInformationForm(
          data = this@ViewModelImpl.accountInformation
        )

        uiState.setState(newUiState)
      }
    }

    override fun submitAccountInformationForm(accountInformation: ViewModel.RegistrationForm.AccountInformation) {
      App.scope.launch {
        either<List<String>, ViewModel.AccountInformation> {
          parZipOrAccumulate(
            {
              ensureNotNull(accountInformation.emailAddress) { "Le champ adresse email est obligatoire" }

              EmailAddress(accountInformation.emailAddress)
                .mapLeft { error: Errors.EmailAddress.Invalid ->
                  when (error) {
                    is Errors.EmailAddress.Invalid.TooLong -> "Le champ adresse email est trop long"
                    is Errors.EmailAddress.Invalid.Format -> "Le champ adresse email est invalide"
                  }
                }
                .bind()
            },
            {
              ensureNotNull(accountInformation.password) { "Le champ mot de passe est obligatoire" }

              Password(accountInformation.password)
                .mapLeft { error: Errors.Password.Invalid ->
                  when (error) {
                    Errors.Password.Invalid.TooShort -> "Le champ mot de passe est trop court"
                    Errors.Password.Invalid.TooLong -> "Le champ mot de passe est trop long"
                    Errors.Password.Invalid.NoLowercaseLetter -> "Le champ mot de passe n'a pas de minuscule"
                    Errors.Password.Invalid.NoUppercaseLetter -> "Le champ mot de passe n'a pas de majuscule"
                    Errors.Password.Invalid.NoDigit -> "Le champ mot de passe n'a pas de chiffre"
                    Errors.Password.Invalid.NoSpecialCharacter -> "Le champ mot de passe n'a pas de caractère special"
                  }
                }
                .bind()
            },
            {
              ensure(accountInformation.password == accountInformation.passwordConfirmation) { "Les mots de passe ne sont pas identiques" }
            },
            {
              accountInformation.partnerCode?.let { partnerCode ->
                PartnerCode(partnerCode)
                  .mapLeft { error ->
                    when (error) {
                      Errors.Partner.Code.Invalid.Blank -> "Le code partenaire ne peut pas être vide si renseigné"
                      Errors.Partner.Code.Invalid.TooLong -> "Le code partenaire est trop long"
                    }.nel()
                  }
                  .bind()
              }
            }
          ) { emailAddress, password, _, partnerCode ->
            ViewModel.AccountInformation(
              emailAddress = emailAddress,
              password = password,
              partnerCode = partnerCode
            )
          }
        }.onLeft { errorMessages: List<String> ->
          errorMessages.forEach { errorMessage: String ->
            Toast.danger(errorMessage)
          }
        }.onRight { accountInformation: ViewModel.AccountInformation ->
          this@ViewModelImpl.accountInformation = accountInformation

          sendRegistration()
        }
      }
    }

    private suspend fun sendRegistration() {
      either<List<String>, Unit> {
        parZipOrAccumulate(
          {
            ensureNotNull(medicalCardInformation) { "Les informations de la carte ne sont pas renseignées" }
          },
          {
            ensureNotNull(professionalInformation) { "Les informations professionnelles ne sont pas renseignées" }
          },
          {
            ensureNotNull(notificationsSettings) { "Les préférences de notification ne sont pas renseignées" }
          },
          {
            ensureNotNull(accountInformation) { "Les informations de compte ne sont pas renseignées" }
          }
        ) { medicalCardInformation, professionalInformation, notificationsSettings, accountInformation ->
          when (professionalInformation) {
            is ProfessionalInformation.Cps -> {
              userRepository
                .registerWithMedicalCardCPS(
                  rppsNumber = professionalInformation.situation.rppsNumber,
                  medicalCardNumber = medicalCardInformation.number,
                  firstName = professionalInformation.situation.firstName,
                  lastName = professionalInformation.situation.lastName,
                  healthDirectoryProfession = professionalInformation.situation.healthDirectoryProfessionCode,
                  healthDirectoryProfessionalCategory = professionalInformation.situation.healthDirectoryProfessionalCategoryCode,
                  healthDirectoryProfessionalStatus = professionalInformation.situation.healthDirectoryProfessionalStatusCode,
                  healthDirectoryExpertise = professionalInformation.situation.healthDirectoryExpertiseCode,
                  healthDirectoryPharmacistSection = professionalInformation.situation.healthDirectoryPharmacistTableSectionCode,
                  department = professionalInformation.department.code,
                  dailyNotificationEnabled = notificationsSettings.daily,
                  weeklyNotificationEnabled = notificationsSettings.weekly,
                  email = accountInformation.emailAddress,
                  password = accountInformation.password,
                  partnerCode = accountInformation.partnerCode
                )
                .onLeft { error: ErrorResponse ->
                  when (error.code) {
                    Errors.MedicalCard.NotFound().code -> {
                      val newUiState = ViewModel.UIState.MedicalCardNotFound(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.MedicalCard.InvalidType().code -> {
                      val newUiState = ViewModel.UIState.MedicalCardExpired(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.MedicalCard.Expired().code -> {
                      val newUiState = ViewModel.UIState.MedicalCardExpired(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.RPPSNumber.Invalid.Format.code -> {
                      val newUiState = ViewModel.UIState.HealthProfessionalNotEligible

                      uiState.setState(newUiState)
                    }

                    Errors.RPPSNumber.AlreadyUsed.code -> {
                      val newUiState = ViewModel.UIState.HealthProfessionalAlreadyRegistered(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.User.EmailAlreadyUsed.code -> {
                      val newUiState = ViewModel.UIState.EmailAddressAlreadyUsed(
                        emailAddress = accountInformation.emailAddress
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.Partner.NotFound().code -> {
                      Toast.danger("Le code partenaire [${accountInformation.partnerCode?.value}] n'existe pas")
                    }

                    Errors.HealthDirectoryMatchingRule.NoMatch.code -> {
                      val newUiState = ViewModel.UIState.HealthProfessionalNotEligible

                      uiState.setState(newUiState)
                    }

                    else -> {
                      Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                    }
                  }
                }
                .onRight {
                  App.routing.navigate(Page.Home(Page.Home.Popup.RegistrationSuccessful(true)))
                }
            }

            is ProfessionalInformation.Cpf -> {
              userRepository
                .registerWithMedicalCardCPF(
                  rppsNumber = professionalInformation.situation.rppsNumber,
                  medicalCardNumber = medicalCardInformation.number,
                  firstName = professionalInformation.situation.firstName,
                  lastName = professionalInformation.situation.lastName,
                  healthDirectoryProfession = professionalInformation.medicalDiploma.healthDirectoryProfessionCode,
                  medicalDiploma = professionalInformation.medicalDiploma.id,
                  universityHospital = professionalInformation.universityHospital.id,
                  dailyNotificationEnabled = notificationsSettings.daily,
                  weeklyNotificationEnabled = notificationsSettings.weekly,
                  email = accountInformation.emailAddress,
                  password = accountInformation.password,
                  partnerCode = accountInformation.partnerCode
                )
                .onLeft { error: ErrorResponse ->
                  when (error.code) {
                    Errors.MedicalCard.NotFound().code -> {
                      val newUiState = ViewModel.UIState.MedicalCardNotFound(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.MedicalCard.InvalidType().code -> {
                      val newUiState = ViewModel.UIState.MedicalCardExpired(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.MedicalCard.Expired().code -> {
                      val newUiState = ViewModel.UIState.MedicalCardExpired(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.RPPSNumber.Invalid.Format.code -> {
                      val newUiState = ViewModel.UIState.HealthProfessionalNotEligible

                      uiState.setState(newUiState)
                    }

                    Errors.RPPSNumber.AlreadyUsed.code -> {
                      val newUiState = ViewModel.UIState.HealthProfessionalAlreadyRegistered(
                        medicalCardNumber = medicalCardInformation.number
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.User.EmailAlreadyUsed.code -> {
                      val newUiState = ViewModel.UIState.EmailAddressAlreadyUsed(
                        emailAddress = accountInformation.emailAddress
                      )

                      uiState.setState(newUiState)
                    }

                    Errors.Partner.NotFound().code -> {
                      Toast.danger("Le code partenaire [${accountInformation.partnerCode?.value}] n'existe pas")
                    }

                    else -> {
                      Toast.danger("Une erreur est survenue, veuillez réessayer plus tard")
                    }
                  }
                }
                .onRight {
                  App.routing.navigate(Page.Home(Page.Home.Popup.RegistrationSuccessful(true)))
                }
            }
          }
        }
      }.onLeft { errors: List<String> ->
        errors.forEach { error: String ->
          Toast.danger(error)
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(state)

  private val stepper = LabodocStepper().apply {
    addStep("Carte CPS/CPF")
    addStep("Informations professionnelles")
    addStep("Résumé de vos informations")
    addStep("Préférences de notification")
    addStep("Création du compte")
  }

  init {
    require("./css/pages/register/sections/with-card-form.css")

    header {
      add(stepper)
    }

    div(className = "page-width") {
      div(className = "container").bind(viewModel.uiState) { uiState ->
        when (uiState) {
          is ViewModel.UIState.MedicalCardInformationForm -> {
            stepper.currentStep.setState(1)

            div(className = "medical-card-form") {
              p {
                rich = true
                content = """
                  Saisissez ci-dessous votre numéro de carte CPS/CPF puis laissez vous guider pour créer votre compte.<br>
                  Cette étape va nous permettre de vous authentifier auprès de l’Agence du Numérique en Santé.
                """
              }

              val form = formPanel<ViewModel.RegistrationForm.MedicalCardInformation> {
                // https://github.com/rjaros/kvision/issues/472
                setAttribute("onsubmit", "return false;")

                labodocText {
                  label = "Numéro de votre carte CPS/CPF"
                  value = uiState.data?.number?.value
                }.bind(
                  ViewModel.RegistrationForm.MedicalCardInformation::medicalCardNumber,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.MedicalCardInformation.validateMedicalCardNumber(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.MedicalCardInformation.validateMedicalCardNumber(it.value) }
                )
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitMedicalCardInformationForm(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.MedicalCardNotFound -> {
            stepper.currentStep.setState(1)

            div(className = "medical-card-not-found") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  Après vérification auprès de l'Agence du Numérique en Santé, aucune carte ayant pour numéro "${uiState.medicalCardNumber.value}" n'a été trouvée.
                """.trimIndent()
              }

              p {
                content = """
                  Si vous venez d'obtenir votre carte, il se peut qu'un délai soit nécessaire avant que notre base de données soit mise à jour sinon veuillez vérifier la validité de votre carte.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Pour plus d'informations, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.MedicalCardExpired -> {
            stepper.currentStep.setState(1)

            div(className = "medical-card-expired") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  Après vérification auprès de l'Agence du Numérique en Santé, la carte ayant pour numéro "${uiState.medicalCardNumber.value}" semble expirée.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Conformément à nos <a href="${Page.TermsOfService().url}">CGU</a> nous regrettons de ne pouvoir donner suite à votre demande d'inscription.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Pour plus d'informations, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalAlreadyRegistered -> {
            stepper.currentStep.setState(1)

            div(className = "health-professional-already-registered") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  Le numéro RPPS rattaché au numéro de carte "${uiState.medicalCardNumber.value}" est déjà en cours d'utilisation sur la plateforme LaboDoc.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Si vous pensez qu'il s'agit d'une erreur ou d'une usurpation d'identité, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalInformationCpsForm -> {
            stepper.currentStep.setState(2)

            div(className = "health-professional-information-cps-form") {
              val form = formPanel<ViewModel.RegistrationForm.HealthProfessionalInformation.Cps> {
                val hasSituationsWithFacility = uiState.situations.any { it.facility != null }
                val previousIndex = uiState.data?.situation?.let { uiState.situations.indexOf(it) }
                var firstIndexFacility: Int? = null

                console.log("previous index")
                console.log(previousIndex)

                val situationField = if (hasSituationsWithFacility)
                  labodocRadioGroup {
                    label = if (hasSituationsWithFacility) "Lieu d'exercice principal" else "Situation principal"
                    options = uiState.situations.mapIndexedNotNull { index, situation ->
                      if (situation.facility != null) {
                        if (firstIndexFacility == null)
                          firstIndexFacility = index
                        console.log("Generating index choice")
                        index.toString() to situation.facility.name
                      } else {
                        console.log("Skip generating")
                        null
                      }
                    }
                    value = (previousIndex ?: firstIndexFacility)?.toString()

                    style {
                      gridColumnStart = 1
                      gridColumnEnd = "-1"
                    }
                  }
                else {
                  labodocSelect {
                    label = "Situation principal"
                    options = uiState.situations.mapIndexed { index, situation ->
                      val label = "${situation.healthDirectoryProfessionName.value} - ${situation.healthDirectoryProfessionalCategoryName.value} - ${situation.healthDirectoryProfessionalStatusName.value}${situation.healthDirectoryExpertiseName?.let { " - ${it.value}" } ?: ""}${situation.healthDirectoryPharmacistTableSectionCode?.let { " - ${it.value}" } ?: ""}"
                      index.toString() to label
                    }
                    value = previousIndex?.toString() ?: "0"
                  }
                }
                  .bind(
                    ViewModel.RegistrationForm.HealthProfessionalInformation.Cps::situationIndex,
                    required = true,
                    requiredMessage = I18n.tr("Field.Required"),
                    validator = null,
                    validatorMessage = null
                  )

                labodocSelect {
                  label = "Département d'exercice"
                  options = uiState.departmentOptions

                  if (hasSituationsWithFacility) {
                    display = Display.NONE

                    situationField.subscribe { situationFieldValue ->
                      situationFieldValue?.toIntOrNull()?.let { index ->
                        this@labodocSelect.value = uiState.situations.getOrNull(index)?.facility?.department?.value
                      }
                    }
                  } else {
                    value = uiState.data?.department?.code?.value
                  }
                }.bind(
                  ViewModel.RegistrationForm.HealthProfessionalInformation.Cps::department,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitHealthProfessionalInformationForm(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalInformationCpfForm -> {
            stepper.currentStep.setState(2)

            div(className = "health-professional-information-cpf-form") {
              val form = formPanel<ViewModel.RegistrationForm.HealthProfessionalInformation.Cpf> {
                labodocSelect {
                  label = "Diplôme d'étude spécialisé"
                  options = uiState.medicalDiplomaOptions
                  value = uiState.data?.medicalDiploma?.id?.value?.toString()
                }.bind(
                  ViewModel.RegistrationForm.HealthProfessionalInformation.Cpf::medicalDiploma,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )

                labodocSelect {
                  label = "Centre hospitalier universitaire"
                  options = uiState.universityHospitalOptions
                  value = uiState.data?.universityHospital?.id?.value?.toString()
                }.bind(
                  ViewModel.RegistrationForm.HealthProfessionalInformation.Cpf::universityHospital,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = null,
                  validatorMessage = null
                )
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitHealthProfessionalInformationForm(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.HealthProfessionalNotEligible -> {
            stepper.currentStep.setState(2)

            div(className = "health-professional-not-eligible") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                rich = true
                content = """
                  LaboDoc est réservé aux médecins, chirurgiens-dentistes, pharmaciens, sages-femmes, infirmiers en activité et aux étudiants en médecine, chirurgie dentaire, pharmacie et maïeutique titulaires d’une carte CPF.<br>
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Conformément à la règlementation et à nos <a href="${Page.TermsOfService().url}">CGU</a>, nous ne sommes malheureusement pas autorisés à vous donner accès aux ressources disponibles sur notre application.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Si vous pensez qu'il s'agit d'une erreur ou pour plus d'informations, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.InformationRecap -> {
            stepper.currentStep.setState(3)

            div(className = "information-recap") {
              val informationRecap = listOf(
                "Carte CPS/CPF : ${uiState.cardNumber.value}",
                "Numéro RPPS : ${uiState.rppsNumber.value}",
                "Nom d'exercice : ${uiState.lastName.value}",
                "Prénom d'exercice : ${uiState.firstName.value}",
                "Profession : ${uiState.labodocMedicalProfessionName.value}",
                "Spécialité : ${uiState.labodocMedicalSpecialityName.value}",
                "Catégories professionnelle : ${uiState.professionalCategoryName.value}",
                "Statut professionnel : ${uiState.professionalStatusName.value}",
                "Département : ${uiState.departmentName.value} (${uiState.departmentCode.value})",
              )

              ul(informationRecap)

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    viewModel.validateInformation()
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.NotificationSettingsForm -> {
            stepper.currentStep.setState(4)

            div(className = "notifications-settings-form") {
              p(className = "title") {
                rich = true
                content = """
                  Assurez une veille continue et sans effort grâce à la notification LaboDoc.
                """
              }

              val form = formPanel<ViewModel.RegistrationForm.NotificationsSettings> {
                labodocRadioGroup {
                  div {
                    p {
                      content = "Instantanéité :"
                    }
                    radio {
                      name = "notification"
                      label = "Je reçois une notification journalière uniquement si une actualité publiée me concerne."
                      value = uiState.data?.daily ?: false
                    }.bind(
                      ViewModel.RegistrationForm.NotificationsSettings::daily,
                      required = false,
                      requiredMessage = I18n.tr("Field.Required"),
                      validator = null,
                      validatorMessage = null
                    )
                  }

                  div {
                    p {
                      content = "Équilibre :"
                    }
                    radio {
                      name = "notification"
                      label = "Je reçois une notification hebdomadaire uniquement si une actualité publiée me concerne."
                      value = uiState.data?.weekly ?: true
                    }.bind(
                      ViewModel.RegistrationForm.NotificationsSettings::weekly,
                      required = false,
                      requiredMessage = I18n.tr("Field.Required"),
                      validator = null,
                      validatorMessage = null
                    )
                  }

                  div {
                    p {
                      content = "Aucune :"
                    }
                    radio {
                      name = "notification"
                      label = "Je préfère ne pas être informé(e) des actualités concernant les médicaments liés à ma pratique."
                      value = uiState.data != null && !uiState.data.daily && !uiState.data.weekly
                    }
                  }
                }

                p {
                  rich = true
                  content =
                    "<b>La pertinence avant tout !</b><br>Quel que soit votre choix, LaboDoc garantit l'envoi de notifications strictement liées à votre spécialité"
                }
              }

              div(className = "buttons") {
                labodocButton("Étape suivante", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitNotificationSettingsForm(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.AccountInformationForm -> {
            stepper.currentStep.setState(5)

            div(className = "account-information-form") {
              val form = formPanel<ViewModel.RegistrationForm.AccountInformation> {
                labodocText {
                  label = "Adresse email"
                  notice = "Ce sera votre identifiant de connexion"
                  input.autocomplete = Autocomplete.USERNAME
                  value = uiState.data?.emailAddress?.value
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::emailAddress,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.AccountInformation.validateEmailAddress(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.AccountInformation.validateEmailAddress(it.value) }
                )

                val passwordField = labodocText {
                  addCssClass("password-row")
                  label = "Mot de passe"
                  noticeRich = true
                  notice =
                    "Doit contenir entre 8 et 32 caractères avec au moins:<ul><li>une minuscule</li><li>une majuscule<li>un chiffre</li><li>caractère spécial</li>"
                  type = InputType.PASSWORD
                  input.autocomplete = Autocomplete.NEW_PASSWORD
                  value = uiState.data?.password?.value
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::password,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validatorMessage = { ViewModel.RegistrationForm.AccountInformation.validatePassword(it.value) },
                  validator = { ViewModel.RegistrationForm.AccountInformation.validatePassword(it.value) == null }
                )

                labodocText {
                  addCssClass("password-row")
                  label = "Confirmer votre mot de passe"
                  type = InputType.PASSWORD
                  input.autocomplete = Autocomplete.NEW_PASSWORD // https://github.com/whatwg/html/issues/3531
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::passwordConfirmation,
                  required = true,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = {
                    ViewModel.RegistrationForm.AccountInformation.validateSamePassword(
                      passwordField.value,
                      it.value
                    ) == null
                  },
                  validatorMessage = {
                    ViewModel.RegistrationForm.AccountInformation.validateSamePassword(
                      passwordField.value,
                      it.value
                    )
                  }
                )

                labodocText {
                  addCssClass("partner-code")
                  label = "Code partenaire"
                  value = uiState.data?.partnerCode?.value
                }.bind(
                  ViewModel.RegistrationForm.AccountInformation::partnerCode,
                  required = false,
                  requiredMessage = I18n.tr("Field.Required"),
                  validator = { ViewModel.RegistrationForm.AccountInformation.validatePartnerCode(it.value) == null },
                  validatorMessage = { ViewModel.RegistrationForm.AccountInformation.validatePartnerCode(it.value) }
                )
              }

              div(className = "buttons") {
                labodocButton("Je m'inscris", className = "next labodoc-background-yellow") {
                  onClick {
                    if (form.validate())
                      viewModel.submitAccountInformationForm(form.getData())
                  }
                }
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }

          is ViewModel.UIState.EmailAddressAlreadyUsed -> {
            stepper.currentStep.setState(5)

            div(className = "email-address-already-used") {
              i(className = "fa-solid fa-circle-exclamation")

              p {
                content = """
                  L'adresse email "${uiState.emailAddress.value}" est déjà en cours d'utilisation sur la plateforme LaboDoc.
                """.trimIndent()
              }

              p {
                rich = true
                content = """
                  Si vous pensez qu'il s'agit d'une erreur ou d'une usurpation d'identité, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
              }

              div(className = "buttons") {
                labodocButton("Retour", className = "return labodoc-background-middle-blue-reverse") {
                  onClick {
                    viewModel.goBack()
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
