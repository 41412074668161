package fr.labodoc.webapp.layouts

import fr.labodoc.app.data.healthprofessional.model.AdminUserModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.components.picture
import io.kvision.html.*
import kotlinx.browser.document
import org.w3c.dom.Node
import org.w3c.dom.events.MouseEvent

class LabodocHeader : Header() {
  init {
    id = "labodoc-header"
    require("./css/labodoc-header.css")

    val user = App.user.getState()

    div {
      id = "labodoc-header-content"

      div {
        id = "labodoc-header-logo"

        div(className = "page-width") {
          val url = when (user) {
            is AdminUserModel -> "/admin"
            is HealthProfessionalUserModel -> "/health-professional"
            null -> Page.Home().url
          }

          navigoLink("", url) {
            picture(require("./img/Labodoc-logotype-horizontal-blanc.svg"), alt = "LaboDoc") {
              addSource("(max-width:700px)", require("./img/Labodoc-logotype-horizontal-couleur.svg"))
            }

            when (user) {
              is HealthProfessionalUserModel -> {
                user.medicalSpeciality.partnership?.let { partnership ->
                  image(partnership.logo.toString())
                }
              }

              else -> {
              }
            }
          }
        }
      }

      div {
        id = "labodoc-header-nav"

        div(className = "page-width") {
          nav {
            val cssOpenClass = "open"

            if (user != null) {
              div(className = "profile") ProfileContainer@{

                button("Profil", "fa-solid fa-user") {
                  onClick {
                    if (this@ProfileContainer.hasCssClass(cssOpenClass))
                      this@ProfileContainer.removeCssClass(cssOpenClass)
                    else
                      this@ProfileContainer.addCssClass(cssOpenClass)
                  }

                  document.addEventListener("click", { event ->
                    if (event is MouseEvent) {
                      val target = event.target

                      if ((target !is Node || this.getElement()
                          ?.contains(target) == false) && this@ProfileContainer.hasCssClass(cssOpenClass)
                      )
                        this@ProfileContainer.removeCssClass(cssOpenClass)
                    }
                  })
                }

                div(className = "dropdown") {
                  if (user is HealthProfessionalUserModel) {
                    p("${user.firstName.value} ${user.lastName.value}", className = "title")
                    navigoLink("Mes informations", Page.HealthProfessionalProfileInformation())
                    if (user.canHaveMedicalInterests)
                      navigoLink("Mes centres d'intérêts", Page.HealthProfessionalProfileMedicalInterests())
                    navigoLink(
                      "Mes préférences de notifications",
                      Page.HealthProfessionalProfileNotificationsSettings()
                    )
                    navigoLink("Nous contacter", Page.Contact())
                  }
                  navigoLink("Se déconnecter", Page.Logout())
                }
              }

              if (user is HealthProfessionalUserModel) {
                div(className = "medical-directory") MedicalDirectoryContainer@{
                  button("L'Annuaire Médical", "fa-solid fa-address-book") {
                    onClick {
                      if (this@MedicalDirectoryContainer.hasCssClass(cssOpenClass))
                        this@MedicalDirectoryContainer.removeCssClass(cssOpenClass)
                      else
                        this@MedicalDirectoryContainer.addCssClass(cssOpenClass)
                    }

                    document.addEventListener("click", { event ->
                      if (event is MouseEvent) {
                        val target = event.target

                        if ((target !is Node || this.getElement()
                            ?.contains(target) == false) && this@MedicalDirectoryContainer.hasCssClass(cssOpenClass)
                        )
                          this@MedicalDirectoryContainer.removeCssClass(cssOpenClass)
                      }
                    })
                  }

                  div(className = "dropdown") {
                    link(
                      "Base publique des médicaments",
                      "https://base-donnees-publique.medicaments.gouv.fr",
                      target = "_blank"
                    ) {
                      val leavingModal = LabodocPopup(
                        closeButton = true,
                        className = "leaving",
                        content = { popup ->
                          p(className = "title") {
                            content = "Visiter le site de Base Med. ANSM"
                          }

                          p(className = "text") {
                            rich = true
                            content = """
                              En poursuivant vous quittez le site LaboDoc.<br>
                              Consultez nos <b><a href="${Page.TermsOfService().url}">conditions générales d’utilisation</a><b>
                            """.trimIndent()
                          }

                          div(className = "buttons") {
                            labodocButton("Annuler") {
                              onClick { popup.hide() }
                            }

                            link("", this@link.url, target = "_blank") {
                              labodocButton("Continuer") {
                                onClick { popup.hide() }
                              }
                            }
                          }
                        }
                      )
                      onClick {
                        it.preventDefault()
                        leavingModal.show()
                      }
                    }

                    link("HAS", "https://www.has-sante.fr/", target = "_blank") {
                      val leavingModal = LabodocPopup(
                        closeButton = true,
                        className = "leaving",
                        content = { popup ->
                          p(className = "title") {
                            content = "Visiter le site de L'HAS"
                          }

                          p(className = "text") {
                            rich = true
                            content = """
                              En poursuivant vous quittez le site LaboDoc.<br>
                              Consultez nos <b><a href="${Page.TermsOfService().url}">conditions générales d’utilisation</a><b>
                            """.trimIndent()
                          }

                          div(className = "buttons") {
                            labodocButton("Annuler") {
                              onClick { popup.hide() }
                            }

                            link("", this@link.url, target = "_blank") {
                              labodocButton("Continuer") {
                                onClick { popup.hide() }
                              }
                            }
                          }
                        }
                      )
                      onClick {
                        it.preventDefault()
                        leavingModal.show()
                      }
                    }

                    navigoLink(
                      "Les Sociétés Savantes",
                      Page.HealthProfessionalLearnedSocietiesList()
                    )

                    navigoLink(
                      "Les Laboratoires",
                      Page.HealthProfessionalLaboratoriesList()
                    )
                  }
                }
              }
            }
          }
        }
      }
    }

    div {
      id = "labodoc-header-spacer"
    }
  }
}
