package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalResponse(
  val nationalIdentifier: HealthProfessionalNationalIdentifierAsString,
  val courtesyCode: String?,
  val practices: Set<Practice>,
  val medicalCard: MedicalCard
) {
  @Serializable
  data class Practice(
    val practiceCourtesyCode: String?,
    val practiceLastName: LastNameAsString,
    val practiceFirstName: FirstNameAsString,
    val professionCode: ProfessionCodeAsString,
    val professionLabel: ProfessionLabelAsString,
    val professionalCategoryCode: ProfessionalCategoryCodeAsString,
    val professionalCategoryLabel: ProfessionalCategoryLabelAsString,
    val expertises: Set<Expertise>,
    val situations: Set<Situation>
  ) {

    @Serializable
    data class Situation(
      val professionalStatusCode: ProfessionalStatusCodeAsString,
      val professionalStatusLabel: ProfessionalStatusLabelAsString,
      val pharmacistSectionCode: PharmacistTableSectionCodeAsString?,
      val functionCode: String?,
      val activityTypeCode: String?,
      val facility: Facility?
    ) {

      @Serializable
      data class Facility(
        val name: String?,
        val officeCedex: String,
        val departmentCode: DepartmentCodeAsString
      )
    }

    @Serializable
    data class Expertise(
      val code: ExpertiseCodeAsString,
      val name: ExpertiseNameAsString
    )
  }

  @Serializable
  data class MedicalCard(
    val typeCode: MedicalCardTypeCodeAsString,
    val typeLabel: MedicalCardTypeLabelAsString,
    val number: MedicalCardNumberAsString
  )
}
