package fr.labodoc.webapp.utils

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.char
import kotlinx.datetime.offsetAt

val Instant.dateFormat: String
  get() {
    val dateTimeFormat = DateTimeComponents.Format {
      dayOfMonth()
      char('/')
      monthNumber()
      char('/')
      year()
    }
    val offset = TimeZone.currentSystemDefault().offsetAt(this)

    return this.format(dateTimeFormat, offset)
  }

val Instant.datetimeFormat: String
  get() {
    val dateTimeFormat = DateTimeComponents.Format {
      dayOfMonth()
      char('/')
      monthNumber()
      char('/')
      year()
      chars(" à ")
      hour()
      char(':')
      minute()
    }
    val offset = TimeZone.currentSystemDefault().offsetAt(this)

    return this.format(dateTimeFormat, offset)
  }
