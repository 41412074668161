package fr.labodoc.webapp.pages.admin.medicines.sections

import fr.labodoc.api.payloads.serializers.MarketingDocumentNameAsString
import fr.labodoc.app.data.admin.model.MedicalInterestModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentName
import fr.labodoc.webapp.components.*
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.time.DateTime
import io.kvision.form.time.dateTime
import io.kvision.i18n.I18n
import io.kvision.types.KFile
import kotlinx.datetime.Clock
import kotlinx.datetime.toKotlinInstant
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlin.js.Date

class MarketingDocumentForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
  fileMandatory: Boolean = false
) : FormPanel<MarketingDocumentForm.Data>(serializer = Data.serializer(), className = "marketing-document-form") {
  @Serializable
  data class Data(
    val fileInput: List<KFile>? = null,
    val name: MarketingDocumentNameAsString? = null,
    val segmentation: SegmentationAsString? = null,
    @Contextual val expireAt: Date? = null
  ) {
    val file: KFile?
      get() = fileInput?.firstOrNull()

    companion object {
      fun validateName(name: String?): String? = MarketingDocumentName(name ?: "")
        .fold(
          { error ->
            when (error) {
              Errors.MarketingDocument.Name.Invalid.Blank -> "Ne peut pas être vide"
              Errors.MarketingDocument.Name.Invalid.TooLong -> "Trop long, taille maximum ${MarketingDocumentName.MAX_LENGTH}"
            }
          },
          {
            null
          }
        )

      fun validateSegmentation(segmentationInput: LabodocSegmentation): String? {
        val segmentation = segmentationInput.data

        val medicalInterestOptions: Collection<MedicalInterestModel>? =
          segmentationInput.medicalProfessions
            ?.flatMap { medicalProfession ->
              medicalProfession.medicalSpecialities
                .mapNotNull { medicalSpeciality ->
                  if (segmentation.medicalSpecialities?.contains(medicalSpeciality.id) == true)
                    medicalSpeciality.medicalInterests
                  else
                    null
                }
                .flatten()
            }
            ?.ifEmpty { null }

        return if (segmentation.medicalProfessions.isNullOrEmpty() || segmentation.medicalSpecialities.isNullOrEmpty())
          "Aucune spécialité renseignée"
        else if (medicalInterestOptions != null && segmentation.medicalInterests.isNullOrEmpty())
          "Aucun centre d'intérêt renseigné"
        else if (segmentation.professionalStatuses.isNullOrEmpty())
          "Aucune situation professionnelle renseignée"
        else if (segmentation.medicalCardTypes.isNullOrEmpty())
          "Aucune type de carte renseigné"
        else
          null
      }

      fun validateExpireAt(expireAtInput: DateTime): String? =
        expireAtInput.value?.let { expireAt ->
          if (expireAt.toKotlinInstant() <= Clock.System.now())
            "La date d'expiration ne peut pas être inférieure ou égale à maintenant"
          else
            null
        }
    }
  }

  init {
    addCustom(
      Data::name,
      labodocText(label = "Nom du document"),
      required = true,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateName(it.value) == null },
      validatorMessage = { Data.validateName(it.value) }
    )

    add(
      Data::fileInput,
      labodocUpload(label = "Document", accept = listOf("application/pdf")),
      required = fileMandatory,
      requiredMessage = I18n.tr("Field.Required")
    )

    addCustom(
      Data::segmentation,
      labodocSegmentation(medicalProfessions, null, professionalStatuses, medicalCardTypes, null, label = "Segmentation du document"),
      required = true,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateSegmentation(it) == null },
      validatorMessage = { Data.validateSegmentation(it) }
    )

    dateTime {
      label = "Expire le"
      format = "DD/MM/YYYY HH:mm"
    }.bind(
      key = Data::expireAt,
      required = false,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateExpireAt(it) == null },
      validatorMessage = { Data.validateExpireAt(it) }
    )
  }
}

fun Container.marketingDocumentForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
): MarketingDocumentForm {
  val marketingDocumentForm = MarketingDocumentForm(
    medicalProfessions,
    professionalStatuses,
    medicalCardTypes
  )

  this.add(marketingDocumentForm)
  return marketingDocumentForm
}
