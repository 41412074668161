package fr.labodoc.webapp

import fr.labodoc.api.payloads.serializers.*
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import io.kvision.navigo.NavigateOptions
import io.kvision.navigo.Navigo

sealed interface AuthenticatedPage

sealed interface AdminPage : AuthenticatedPage

sealed interface HealthProfessionalPage : AuthenticatedPage

sealed interface Page {
  val url: String

  class Home(
    val popup: Popup? = null
  ) : Page {
    companion object {
      const val URL_PATTERN = "/"

      const val REGISTRATION_SUCCESSFUL_QUERY_KEY = "registrationSuccessful"
      const val REGISTRATION_CONFIRM_EMAIL_TOKEN_QUERY_KEY = "registrationConfirmEmailToken"
      const val APPLICATION_SUCCESSFUL_QUERY_KEY = "applicationSuccessful"
      const val APPLICATION_CONFIRM_EMAIL_TOKEN_QUERY_KEY = "applicationConfirmEmailToken"
      const val RESET_PASSWORD_TOKEN_QUERY_KEY = "resetPasswordToken"
    }

    sealed interface Popup {
      data class RegistrationSuccessful(val success: Boolean) : Popup
      data class RegistrationConfirmEmail(val token: String) : Popup
      data class ApplicationSuccessful(val success: Boolean) : Popup
      data class ApplicationConfirmEmail(val token: String) : Popup
      data class ResetPassword(val token: String): Popup
    }

    override val url: String
      get() {
        return when (popup) {
          is Popup.RegistrationSuccessful -> "${URL_PATTERN}?${REGISTRATION_SUCCESSFUL_QUERY_KEY}=${popup.success}"
          is Popup.RegistrationConfirmEmail -> "${URL_PATTERN}?${REGISTRATION_CONFIRM_EMAIL_TOKEN_QUERY_KEY}=${popup.token}"
          is Popup.ApplicationSuccessful -> "${URL_PATTERN}?${APPLICATION_SUCCESSFUL_QUERY_KEY}=${popup.success}"
          is Popup.ApplicationConfirmEmail -> "${URL_PATTERN}?${APPLICATION_CONFIRM_EMAIL_TOKEN_QUERY_KEY}=${popup.token}"
          is Popup.ResetPassword -> "${URL_PATTERN}?${RESET_PASSWORD_TOKEN_QUERY_KEY}=${popup.token}"
          null -> URL_PATTERN
        }
      }
  }

  class Login(
    val redirectTo: String? = null
  ) : Page {
    companion object {
      const val URL_PATTERN = "/login"

      const val REDIRECT_TO_QUERY_KEY = "redirectTo"
    }

    override val url: String = "${URL_PATTERN}${redirectTo?.let { "?${REDIRECT_TO_QUERY_KEY}=$redirectTo" } ?: ""}"
  }

  class Logout : Page {
    companion object {
      const val URL_PATTERN = "/logout"
    }

    override val url: String = URL_PATTERN
  }

  class Register : Page {
    companion object {
      const val URL_PATTERN = "/register"
    }

    override val url: String = URL_PATTERN
  }

  class Contact : Page {
    companion object {
      const val URL_PATTERN = "/contact"
    }

    override val url: String = URL_PATTERN
  }

  class FAQ : Page {
    companion object {
      const val URL_PATTERN = "/faq"
    }

    override val url: String = URL_PATTERN
  }

  class About : Page {
    companion object {
      const val URL_PATTERN = "/about"
    }

    override val url: String = URL_PATTERN
  }

  class WhoAreWe : Page {
    companion object {
      const val URL_PATTERN = "/who-are-we"
    }

    override val url: String = URL_PATTERN
  }

  class PrivacyPolicy : Page {
    companion object {
      const val URL_PATTERN = "https://labodoc-media.s3.gra.io.cloud.ovh.net/legal/privacy_policy.pdf"
    }

    override val url: String = URL_PATTERN
  }

  class LegalNotice : Page {
    companion object {
      const val URL_PATTERN = "https://labodoc-media.s3.gra.io.cloud.ovh.net/legal/legal_notice.pdf"
    }

    override val url: String = URL_PATTERN
  }

  class TermsOfService : Page {
    companion object {
      const val URL_PATTERN = "https://labodoc-media.s3.gra.io.cloud.ovh.net/legal/terms_of_service.pdf"
    }

    override val url: String = URL_PATTERN
  }

  class HealthProfessionalDashboard : Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/dashboard"
    }

    override val url: String = URL_PATTERN
  }

  class HealthProfessionalMedicineSheet(
    val medicineId: MedicineIdAsString
  ) : Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/medicines/:id"
    }

    override val url: String = URL_PATTERN
      .replace(":id", medicineId.value.toString())
  }

  class HealthProfessionalLaboratoriesList : Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/laboratories"
    }

    override val url: String = URL_PATTERN
  }

  class HealthProfessionalLearnedSocietiesList : Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/learned-societies"
    }

    override val url: String = URL_PATTERN
  }

  class HealthProfessionalProfileInformation: Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/profile/information"
    }

    override val url: String = URL_PATTERN
  }

  class HealthProfessionalProfileMedicalInterests: Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/profile/medical-interests"
    }

    override val url: String = URL_PATTERN
  }

  class HealthProfessionalProfileNotificationsSettings: Page, HealthProfessionalPage {
    companion object {
      const val URL_PATTERN = "/health-professional/profile/notifications-settings"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeUsersApplicationsList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/users/applications"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLaboratoriesList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/laboratories"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLaboratorySheet(
    val laboratoryId: LaboratoryIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/laboratories/:id"
    }

    override val url: String = URL_PATTERN
      .replace(":id", laboratoryId.value.toString())
  }

  class AdminBackOfficeLaboratoryCreate : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/laboratories/create"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLaboratoryUpdate(
    val laboratoryId: LaboratoryIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/laboratories/:id/update"
    }

    override val url: String = URL_PATTERN
      .replace(":id", laboratoryId.value.toString())
  }

  class AdminBackOfficeMedicinesList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/medicines"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeMedicineSheet(
    val medicineId: MedicineIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/medicines/:id"
    }

    override val url: String = URL_PATTERN
      .replace(":id", medicineId.value.toString())
  }

  class AdminBackOfficeMedicineCreate : Page, AdminPage {
      companion object {
        const val URL_PATTERN = "/admin/medicines/create"
      }

      override val url: String = URL_PATTERN
  }

  class AdminBackOfficeMedicalProfessionList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/medical-professions"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLaboratoryMedicineCreate (
    val laboratoryId: LaboratoryIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/laboratory/:id/medicines/create"
    }

    override val url: String = URL_PATTERN
      .replace(":id", laboratoryId.value.toString())
  }

  class AdminBackOfficeMedicineUpdate(
    val medicineId: MedicineIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/medicines/:id/update"
    }

    override val url: String = URL_PATTERN
      .replace(":id", medicineId.value.toString())
  }

  class AdminBackOfficeHealthDirectory : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/health-directory"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLearnedSocietiesList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/learned-societies"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLearnedSocietyCreate : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/learned-societies/create"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeLearnedSocietyUpdate(
    val learnedSocietyId: LearnedSocietyIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/learned-societies/:id/update"
    }

    override val url: String = URL_PATTERN
      .replace(":id", learnedSocietyId.value.toString())
  }

  class AdminBackOfficeMessagesList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/messages"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeMessageSheet(
    val messageId: MessageIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/messages/:id"
    }

    override val url: String = URL_PATTERN
      .replace(":id", messageId.value.toString())
  }

  class AdminBackOfficeMessageCreate : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/messages/create"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeMessageUpdate(
    val messageId: MessageIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/messages/:id/update"
    }

    override val url: String = URL_PATTERN
      .replace(":id", messageId.value.toString())
  }

  class AdminBackOfficeMedicalDiplomasList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/medical-diplomas"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficeUniversityHospitalsList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/university-hospitals"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficePartnerList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/partners"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficePartnershipList : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/partnerships"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficePartnershipCreate : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/partnerships/create"
    }

    override val url: String = URL_PATTERN
  }

  class AdminBackOfficePartnershipUpdate(
    val partnershipId: PartnershipIdAsString
  ) : Page, AdminPage {
    companion object {
      const val URL_PATTERN = "/admin/partnerships/:id/update"
    }

    override val url: String = URL_PATTERN
      .replace(":id", partnershipId.value.toString())
  }
}

fun Navigo.navigate(to: Page, options: NavigateOptions) = navigate(to.url, options)
fun Navigo.navigate(to: Page) = navigate(to.url)
