package fr.labodoc.webapp.pages.healthProfessional.messages.components

import fr.labodoc.app.data.healthprofessional.model.*
import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.utils.datetimeFormat
import io.kvision.core.onChange
import io.kvision.form.check.checkBox
import io.kvision.html.*

class MessageModal(
  message: MessageModel,
  onFavoriteClicked: (Boolean) -> Unit
) {
  private val modal = LabodocPopup(
    closeButton = true,
    icon = null,
    image = message.bannerUrl.toString(),
    className = "message-modal",
    content = { popup ->
      div(className = "container") {
        header {
          p(className = "type") {
            rich = true
            content = when (message) {
              is FlashInfoMessageModel -> """<i class="fa-solid fa-bell"></i> Flash Info"""
              is InvitationMessageModel -> """<i class="fa-solid fa-envelope"></i> Invitation"""
            }
          }


          checkBox {
            addCssClass("favorite")
            value = message.favorite

            onChange {
              onFavoriteClicked(value)
            }
          }
        }

        p(message.title.value, className = "title")

        p(className = "author") {
          when (message) {
            is LabodocMessageModel -> {
              content = "LaboDoc"
            }

            is LaboratoryMessageModel -> {
              content = message.laboratory.name.value
            }

            is MedicineMessageModel -> {
              navigoLink(message.medicine.name.value, Page.HealthProfessionalMedicineSheet(message.medicine.id)) {
                onClick { popup.hide() }
              }
              content = " - ${message.laboratory.name.value}"
            }

            is LearnedSocietyMessageModel -> {
              content = message.learnedSociety.name.value
            }
          }
        }

        p(message.content.value.replace("\n", "<br>"), rich = true, className = "text")

        if (message is MedicineInvitationModel) {
          p("Rendez-vous le: ${message.eventAt.datetimeFormat}", className = "date")
        }

        div(className = "actions") {
          message.documentUrl?.let {
            link(
              "Consulter le document",
              it.toString(),
              target = "_blank",
              className = "labodoc-background-deep-blue-reverse"
            ) {
              icon("fa-solid fa-download")
            }
          }

          if (message is InvitationMessageModel) {
            message.formUrl?.let { formUrl ->
              link(
                label = "S'inscrire à cet évènement",
                url = formUrl.toString(),
                target = "_blank",
                className = "labodoc-background-middle-blue"
              )
            }
          }
        }
      }
    }
  )

  init {
    require("./css/pages/healthProfessional/messages/components/message-modal.css")
  }

  fun show() {
    modal.show()
  }

  fun hide() {
    modal.hide()
  }
}
