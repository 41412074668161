package fr.labodoc.api.resources

import io.ktor.resources.*

@Resource("users")
class Users {
  @Resource("login")
  class Login(val users: Users = Users())

  @Resource("password")
  class Password(val users: Users = Users()) {
    @Resource("reset")
    class Reset(val password: Password = Password()) {
      @Resource("{passwordToken}/validate")
      class Validate(val passwordToken: String, val reset: Reset = Reset())
    }
  }

  @Resource("self")
  class Self(val users: Users = Users()) {
    @Resource("logout")
    class Logout(val self: Self = Self())

    @Resource("password")
    class Password(val self: Self = Self())

    @Resource("medical-interests")
    class MedicalInterests(val self: Self = Self())

    @Resource("notifications-settings")
    class NotificationSettings(val self: Self = Self())
  }
}
